import http from './httpRequest'

/**
 * AI聊天
 * @param {Object} data 请求数据 
 * @param {String} data.message 用户输入的消息
 * @param {String} data.sessionId 会话ID
 * @returns {Promise} 返回Promise对象
 */
export function aiChat(data) {
  return http({
    url: '/v1/ai/chat',
    method: 'post',
    data
  })
} 