import http from './httpRequest'

/**
 * 用户登录
 * @param {object} data 登录信息
 * @param {string} data.userName 用户名
 * @param {string} data.password 密码
 * @param {number} data.terminal 登录终端 0:web 1:app 2:pc
 * @returns {Promise} 登录结果
 */
export function login(data) {
  return http({
    url: '/login',
    method: 'post',
    data
  })
}

/**
 * 用户注册
 * @param {object} data 注册信息
 * @param {string} data.userName 用户名，最大长度64字符
 * @param {string} data.password 密码，长度5-20字符
 * @param {string} data.nickName 昵称，最大长度64字符
 * @returns {Promise} 注册结果
 */
export function register(data) {
  return http({
    url: '/register',
    method: 'post',
    data
  })
}

/**
 * 退出登录
 * @returns {Promise} 退出结果
 */
export function logout() {
  return http({
    url: '/logout',
    method: 'post'
  })
}

/**
 * 获取用户信息
 * @returns {Promise} 用户信息
 */
export function getUserInfo() {
  return http({
    url: '/user/info',
    method: 'get'
  })
} 