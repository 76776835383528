<template>
  <div>
    <!-- 添加互动统计栏 -->
    <div class="interaction-bar" v-if="!isShow">
      <div class="interaction-item" @click="handleLike">
        <div class="icon-wrapper" :class="{ 'active': video.isLiked }">
          <i :class="video.isLiked ? 'el-icon-star-on' : 'el-icon-star-off'"></i>
        </div>
        <div class="counter">{{ formatNumber(video.likes || 0) }}</div>
      </div>
      <div class="interaction-item" @click="handleComment">
        <div class="icon-wrapper">
          <i class="el-icon-chat-dot-round"></i>
        </div>
        <div class="counter">{{ formatNumber(video.comments || 0) }}</div>
      </div>
      <div class="interaction-item" @click="handleFavorite">
        <div class="icon-wrapper" :class="{ 'active': video.isFavorite }">
          <i class="el-icon-star-on"></i>
        </div>
        <div class="counter">{{ formatNumber(video.favorites || 0) }}</div>
      </div>
      <div class="interaction-item" @click="$emit('open')">
        <div class="icon-wrapper">
          <i class="el-icon-share"></i>
        </div>
        <div class="counter">{{ formatNumber(video.shares || 0) }}</div>
      </div>
    </div>

    <!-- 原有的分享弹窗 -->
    <div class="share-container" :class="{ 'show': isShow }">
      <div class="share-overlay" @click="$emit('close')"></div>
      <div class="share-content">
        <div class="share-header">
          <div class="header-title">分享视频</div>
          <i class="el-icon-close close-icon" @click="$emit('close')"></i>
        </div>
        
        <div class="share-body">
          <!-- 直接分享选项 -->
          <div class="share-section">
            <h3 class="section-title">分享至</h3>
            <div class="share-platforms">
              <div class="platform-item" 
                   v-for="platform in platforms" 
                   :key="platform.id" 
                   @click="shareTo(platform.id)"
                   :class="{ 'hover-effect': true }">
                <div class="platform-icon" :style="{backgroundColor: platform.color}">
                  <i :class="platform.icon"></i>
                </div>
                <div class="platform-name">{{ platform.name }}</div>
              </div>
            </div>
          </div>
          
          <!-- 链接分享 -->
          <div class="share-section">
            <h3 class="section-title">视频链接</h3>
            <div class="share-link">
              <el-input v-model="shareLink" readonly>
                <el-button slot="append" @click="copyLink" :class="{ 'copied': isCopied }">
                  {{ isCopied ? '已复制' : '复制' }}
                </el-button>
              </el-input>
              <div class="link-tip">复制链接，发送给朋友</div>
            </div>
          </div>
          
          <!-- 二维码分享 -->
          <div class="share-section">
            <h3 class="section-title">扫描二维码</h3>
            <div class="qr-code">
              <div class="qr-image">
                <img src="https://via.placeholder.com/150" alt="QR Code">
              </div>
              <div class="qr-tip">扫一扫，在手机上继续观看</div>
              <el-button size="small" type="primary" @click="saveQRCode" class="save-btn">保存图片</el-button>
            </div>
          </div>
          
          <!-- 举报与不感兴趣 -->
          <div class="share-actions">
            <div class="action-item" @click="reportVideo">
              <i class="el-icon-warning-outline"></i>
              举报
            </div>
            <div class="action-item" @click="notInterested">
              <i class="el-icon-close"></i>
              不感兴趣
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VideoShare',
  props: {
    video: {
      type: Object,
      required: true
    },
    isShow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      platforms: [
        { id: 'wechat', name: '微信', icon: 'el-icon-chat-dot-round', color: '#07C160' },
        { id: 'moments', name: '朋友圈', icon: 'el-icon-share', color: '#07C160' },
        { id: 'qq', name: 'QQ', icon: 'el-icon-message', color: '#12B7F5' },
        { id: 'weibo', name: '微博', icon: 'el-icon-s-promotion', color: '#E6162D' },
        { id: 'telegram', name: 'Telegram', icon: 'el-icon-position', color: '#0088CC' },
        { id: 'message', name: '短信', icon: 'el-icon-message-solid', color: '#5C87FF' },
        { id: 'mail', name: '邮件', icon: 'el-icon-message', color: '#FF9500' },
        { id: 'more', name: '更多', icon: 'el-icon-more', color: '#333333' }
      ],
      shareLink: `https://example.com/video/${this.video.id}`,
      isCopied: false
    }
  },
  methods: {
    formatNumber(num) {
      if (num >= 10000) {
        return (num / 10000).toFixed(1) + 'w';
      }
      return num;
    },
    handleLike() {
      // 切换点赞状态
      this.video.isLiked = !this.video.isLiked;
      if (this.video.isLiked) {
        this.video.likes = (this.video.likes || 0) + 1;
      } else {
        this.video.likes = Math.max((this.video.likes || 0) - 1, 0);
      }
      this.$emit('like', this.video.isLiked);
    },
    handleComment() {
      this.$emit('comment');
    },
    handleFavorite() {
      // 切换收藏状态
      this.video.isFavorite = !this.video.isFavorite;
      if (this.video.isFavorite) {
        this.video.favorites = (this.video.favorites || 0) + 1;
      } else {
        this.video.favorites = Math.max((this.video.favorites || 0) - 1, 0);
      }
      this.$emit('favorite', this.video.isFavorite);
    },
    shareTo(platformId) {
      const platform = this.platforms.find(p => p.id === platformId)
      this.$message({
        message: `正在分享到${platform.name}`,
        type: 'success'
      })
      
      if (this.video) {
        this.video.shares = (this.video.shares || 0) + 1
      }
      
      setTimeout(() => {
        this.$emit('close')
      }, 1500)
    },
    copyLink() {
      const input = document.createElement('input')
      input.value = this.shareLink
      document.body.appendChild(input)
      input.select()
      document.execCommand('copy')
      document.body.removeChild(input)
      
      this.isCopied = true
      this.$message({
        message: '链接已复制到剪贴板',
        type: 'success'
      })
      
      setTimeout(() => {
        this.isCopied = false
      }, 2000)
    },
    saveQRCode() {
      this.$message({
        message: '二维码已保存到相册',
        type: 'success'
      })
    },
    reportVideo() {
      this.$confirm('确定要举报该视频吗?', '举报', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$message({
          type: 'success',
          message: '举报已提交，我们会尽快处理'
        })
        this.$emit('close')
      }).catch(() => {})
    },
    notInterested() {
      this.$message({
        message: '已减少此类内容的推荐',
        type: 'info'
      })
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
/* 新增互动栏样式 */
.interaction-bar {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  right: 20px;
  bottom: 120px;
  z-index: 100;
}

.interaction-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 18px;
  cursor: pointer;
}

.icon-wrapper {
  width: 45px;
  height: 45px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  transition: all 0.3s;
}

.icon-wrapper i {
  font-size: 22px;
  color: white;
}

.icon-wrapper.active {
  background-color: #ff4757;
}

.counter {
  font-size: 12px;
  color: white;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

/* 原有的样式 */
.share-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
}

.share-container.show {
  opacity: 1;
  visibility: visible;
}

.share-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  transition: all 0.3s ease;
}

.share-content {
  position: relative;
  width: 500px;
  max-width: 90%;
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  transform: translateY(20px);
  transition: all 0.3s ease;
  overflow: hidden;
}

.share-container.show .share-content {
  transform: translateY(0);
}

.share-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-bottom: 1px solid #eee;
}

.header-title {
  font-size: 18px;
  font-weight: 600;
  color: #333;
}

.close-icon {
  font-size: 20px;
  cursor: pointer;
  color: #999;
  transition: all 0.3s ease;
}

.close-icon:hover {
  color: #333;
  transform: rotate(90deg);
}

.share-body {
  padding: 20px;
  max-height: 70vh;
  overflow-y: auto;
}

.share-section {
  margin-bottom: 30px;
}

.section-title {
  font-size: 16px;
  margin-bottom: 20px;
  color: #333;
  font-weight: 500;
}

.share-platforms {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

.platform-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
}

.platform-item.hover-effect:hover {
  transform: translateY(-5px);
}

.platform-icon {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 28px;
  margin-bottom: 10px;
  transition: all 0.3s ease;
}

.platform-item:hover .platform-icon {
  transform: scale(1.1);
}

.platform-name {
  font-size: 14px;
  color: #666;
}

.share-link {
  margin-bottom: 15px;
}

.link-tip {
  font-size: 12px;
  color: #999;
  margin-top: 8px;
}

.qr-code {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f8f8f8;
  border-radius: 12px;
}

.qr-image {
  width: 180px;
  height: 180px;
  margin-bottom: 15px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.qr-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.qr-tip {
  font-size: 14px;
  color: #666;
  margin-bottom: 15px;
}

.save-btn {
  padding: 8px 20px;
  border-radius: 20px;
  transition: all 0.3s ease;
}

.save-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.share-actions {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid #eee;
}

.action-item {
  display: flex;
  align-items: center;
  color: #666;
  font-size: 14px;
  margin: 0 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  padding: 8px 16px;
  border-radius: 20px;
}

.action-item:hover {
  background-color: #f5f5f5;
  color: #333;
}

.action-item i {
  margin-right: 6px;
}

.copied {
  background-color: #67C23A !important;
  color: white !important;
}

/* 自定义滚动条样式 */
.share-body::-webkit-scrollbar {
  width: 6px;
}

.share-body::-webkit-scrollbar-thumb {
  background-color: #ddd;
  border-radius: 3px;
}

.share-body::-webkit-scrollbar-track {
  background-color: #f5f5f5;
  border-radius: 3px;
}
</style>