<template>
  <div class="login-container">
    <div class="animated-background">
      <div class="circle"></div>
      <div class="circle"></div>
      <div class="circle"></div>
      <div class="circle"></div>
      <div class="particles">
        <div v-for="n in 20" :key="n" class="particle"></div>
      </div>
      <div class="dna-helix">
        <div v-for="n in 10" :key="`dna-${n}`" class="dna-strand" 
            :style="{ animationDelay: `${n * 0.2}s`, top: `${n * 10}%` }"></div>
      </div>
      <div class="medical-icons">
        <div v-for="icon in medicalIcons" :key="icon.id" class="medical-icon"
            :class="icon.class" :style="icon.style"></div>
      </div>
      <!-- 医学分子结构背景 -->
      <div class="molecule-bg">
        <div v-for="n in 5" :key="`molecule-${n}`" class="molecule"
            :style="{ top: `${n * 18}%`, left: `${n * 20}%`, animationDelay: `${n * 0.5}s` }"></div>
      </div>
      <!-- 心电图背景 -->
      <div class="ecg-bg">
        <div class="ecg-line"></div>
      </div>
      <!-- 医疗图案背景 -->
      <div class="medical-patterns">
        <div class="pattern pattern-1"></div>
        <div class="pattern pattern-2"></div>
        <div class="pattern pattern-3"></div>
      </div>
      <!-- 渐变光效 -->
      <div class="glow-effect"></div>
      <!-- 浮动医疗元素 -->
      <div class="floating-elements">
        <div class="medical-element pill-icon"></div>
        <div class="medical-element syringe-icon"></div>
        <div class="medical-element clipboard-icon"></div>
        <div class="medical-element dna-icon"></div>
        <div class="medical-element microscope-icon"></div>
      </div>
    </div>
    <div class="login-content">
      <div class="login-left">
        <div class="ai-features">
          <div class="title-container">
            <div class="title-icon"></div>
            <h1 class="animate-title">医路智通</h1>
          </div>
          <p class="subtitle animate-subtitle">智慧医疗服务平台</p>
          <div class="feature-list">
            <div class="feature-item" v-for="(feature, index) in features" :key="index"
                 :style="{ animationDelay: index * 0.2 + 's' }">
              <el-icon><component :is="feature.icon" /></el-icon>
              <div class="feature-content">
                <span class="feature-title">{{ feature.text }}</span>
                <span class="feature-desc">{{ feature.description }}</span>
              </div>
            </div>
          </div>
          <div class="ai-highlight">
            <div class="highlight-text">
              <span class="highlight-icon">🏥</span>
              <span>AI 智诊 · 便捷挂号 · 精准医疗</span>
            </div>
          </div>
        </div>
      </div>
      <div class="login-right">
        <el-form :model="loginForm" :rules="rules" ref="loginForm" class="login-form">
          <h2 class="login-title animate-title">欢迎使用</h2>
          <p class="login-subtitle animate-subtitle">登录您的医路智通账号</p>
          <el-form-item prop="userName">
            <el-input v-model="loginForm.userName" placeholder="用户名" class="animate-input">
              <template #prefix>
                <el-icon><User /></el-icon>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input v-model="loginForm.password" type="password" placeholder="密码" class="animate-input">
              <template #prefix>
                <el-icon><Lock /></el-icon>
              </template>
            </el-input>
          </el-form-item>
          <div class="form-options">
            <el-checkbox v-model="rememberMe">记住我</el-checkbox>
            <el-link type="primary">忘记密码？</el-link>
          </div>
          <div class="button-group">
            <el-button type="primary" @click="handleLogin" class="login-button animate-button" :loading="loading">
              <span class="button-content" v-if="!loading">
                <el-icon><Right /></el-icon>
                <span>登录</span>
              </span>
            </el-button>
            <el-button @click="handleRegister" class="register-button animate-button" :disabled="loading">
              <span class="button-content">
                <el-icon><Plus /></el-icon>
                <span>注册新账号</span>
              </span>
            </el-button>
          </div>
        </el-form>
      </div>
    </div>
    <div class="footer-info">
      <span>© 2025 医路智通 - 智慧医疗服务平台 版权所有</span>
    </div>
  </div>
</template>

<script>
import { User, Lock, ChatDotRound, Message, VideoCamera, Cpu, Right, Plus } from '@element-plus/icons-vue'
import { login } from '@/api/user'

export default {
  name: 'Login',
  components: {
    User,
    Lock,
    ChatDotRound,
    Message,
    VideoCamera,
    Cpu,
    Right,
    Plus
  },
  data() {
    return {
      loginForm: {
        userName: '',
        password: '',
        terminal: 0 // web端默认为0
      },
      rememberMe: false,
      loading: false,
      rules: {
        userName: [
          { required: true, message: '请输入用户名', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' }
        ]
      },
      features: [
        { 
          icon: 'ChatDotRound', 
          text: '智能问诊',
          description: '基于AI的智能问诊系统，提供初步诊断与健康指导'
        },
        { 
          icon: 'Message', 
          text: '在线咨询',
          description: '与医生一对一沟通，医患信息安全加密'
        },
        { 
          icon: 'VideoCamera', 
          text: '远程会诊',
          description: '多学科专家团队远程协作，解决疑难杂症'
        },
        { 
          icon: 'Cpu', 
          text: '健康管理',
          description: 'AI智能分析身体数据，提供个性化健康方案'
        }
      ],
      medicalIcons: [
        { id: 1, class: 'heart-beat', style: { top: '15%', left: '10%' } },
        { id: 2, class: 'pill', style: { top: '30%', right: '15%' } },
        { id: 3, class: 'stethoscope', style: { bottom: '20%', left: '20%' } },
        { id: 4, class: 'pulse', style: { top: '70%', right: '10%' } },
        { id: 5, class: 'medical-cross', style: { top: '40%', left: '50%' } }
      ]
    }
  },
  methods: {
    handleLogin() {
      this.$refs.loginForm.validate(async (valid) => {
        if (valid) {
          try {
            this.loading = true
            const data = await login({
              userName: this.loginForm.userName,
              password: this.loginForm.password,
              terminal: this.loginForm.terminal
            })
            
            // 登录成功，保存用户信息和token
            if (this.rememberMe) {
              localStorage.setItem('token', data.token)
              localStorage.setItem('userInfo', JSON.stringify(data.userInfo))
            } else {
              sessionStorage.setItem('accessToken', data.accessToken)
              sessionStorage.setItem('refreshToken', data.refreshToken)
              sessionStorage.setItem('userInfo', JSON.stringify(data.userInfo))
            }
            
            this.$message({
              message: '登录成功',
              type: 'success',
              duration: 1500
            })
            
            // 判断是否有重定向的路径，如果有则跳转到之前的页面
            const redirect = this.$route.query.redirect
            if (redirect) {
              this.$router.push(redirect)
            } else {
              // 默认跳转到首页
              this.$router.push('/home/chat')
            }
          } catch (error) {
            console.error('登录失败：', error)
            this.$message({
              message: error.response?.data?.message || '登录失败，请检查用户名和密码',
              type: 'error',
              duration: 2000
            })
          } finally {
            this.loading = false
          }
        }
      })
    },
    handleRegister() {
      this.$router.push('/register')
    }
  }
}
</script>

<style scoped>
.login-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(135deg, #00796b 0%, #004d40 100%), url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100"><path d="M20 50 A30 30 0 0 1 80 50 A30 30 0 0 1 20 50 Z" fill="none" stroke="%23B2EBF2" stroke-width="0.5" opacity="0.2"/></svg>');
  background-blend-mode: overlay;
  position: relative;
  overflow: hidden;
}

.animated-background {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.circle {
  position: absolute;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
  animation: float 15s infinite;
}

.circle:nth-child(1) {
  width: 300px;
  height: 300px;
  top: -150px;
  left: -150px;
  animation-delay: 0s;
}

.circle:nth-child(2) {
  width: 200px;
  height: 200px;
  top: 50%;
  right: -100px;
  animation-delay: 5s;
}

.circle:nth-child(3) {
  width: 150px;
  height: 150px;
  bottom: -75px;
  left: 50%;
  animation-delay: 10s;
}

.circle:nth-child(4) {
  width: 250px;
  height: 250px;
  top: 25%;
  right: 25%;
  animation-delay: 7s;
}

.particles {
  position: absolute;
  width: 100%;
  height: 100%;
}

.particle {
  position: absolute;
  width: 4px;
  height: 4px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  animation: particleFloat 20s infinite linear;
}

.particle:nth-child(1) { left: 10%; top: 20%; animation-delay: 0s; }
.particle:nth-child(2) { left: 20%; top: 40%; animation-delay: 2s; }
.particle:nth-child(3) { left: 30%; top: 60%; animation-delay: 4s; }
.particle:nth-child(4) { left: 40%; top: 80%; animation-delay: 6s; }
.particle:nth-child(5) { left: 50%; top: 30%; animation-delay: 8s; }
.particle:nth-child(6) { left: 60%; top: 50%; animation-delay: 10s; }
.particle:nth-child(7) { left: 70%; top: 70%; animation-delay: 12s; }
.particle:nth-child(8) { left: 80%; top: 90%; animation-delay: 14s; }
.particle:nth-child(9) { left: 90%; top: 40%; animation-delay: 16s; }
.particle:nth-child(10) { left: 95%; top: 60%; animation-delay: 18s; }

@keyframes particleFloat {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 0;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(-100vh) rotate(360deg);
    opacity: 0;
  }
}

@keyframes float {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }
  50% {
    transform: translate(30px, 30px) rotate(180deg);
  }
  100% {
    transform: translate(0, 0) rotate(360deg);
  }
}

.login-content {
  display: flex;
  width: 1000px;
  height: 600px;
  background: rgba(255, 255, 255, 0.95);
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.2);
  position: relative;
  backdrop-filter: blur(10px);
  animation: fadeIn 0.8s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.login-left {
  flex: 1;
  background: linear-gradient(135deg, #1E88E5 0%, #00796b 100%);
  padding: 40px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.login-left::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(45deg, transparent, rgba(255, 255, 255, 0.1), transparent);
  transform: translateX(-100%);
  animation: shine 3s infinite;
}

@keyframes shine {
  100% {
    transform: translateX(100%);
  }
}

.title-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.title-icon {
  width: 40px;
  height: 40px;
  margin-right: 15px;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" fill="%23FFFFFF"><path d="M25 2 C12 2, 2 12, 2 25 C2 38, 12 48, 25 48 C38 48, 48 38, 48 25 C48 12, 38 2, 25 2 Z M25 44 C14 44, 6 36, 6 25 C6 14, 14 6, 25 6 C36 6, 44 14, 44 25 C44 36, 36 44, 25 44 Z" stroke="%2380DEEA" stroke-width="1"/><path d="M25 14 L25 36 M14 25 L36 25" stroke="%23FFFFFF" stroke-width="2"/><circle cx="25" cy="25" r="5" fill="%2380DEEA" opacity="0.7"/></svg>');
  background-size: contain;
  background-repeat: no-repeat;
  animation: rotateIcon 10s infinite linear;
}

@keyframes rotateIcon {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.login-left h1 {
  font-size: 46px;
  margin-bottom: 5px;
  font-weight: 700;
  animation: slideInLeft 0.8s ease-out;
  background: linear-gradient(45deg, #fff, #e3f2fd);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 2px 10px rgba(0, 255, 255, 0.15);
  letter-spacing: 2px;
  position: relative;
}

.login-left h1::first-letter {
  font-size: 52px;
  color: #80DEEA;
}

.subtitle {
  font-size: 20px;
  margin-bottom: 40px;
  opacity: 0.9;
  animation: slideInLeft 0.8s ease-out 0.2s backwards;
}

.feature-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.feature-item {
  display: flex;
  align-items: flex-start;
  gap: 15px;
  font-size: 18px;
  animation: slideInLeft 0.8s ease-out backwards;
  transition: all 0.3s ease;
  padding: 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.1);
}

.feature-item:hover {
  transform: translateX(10px);
  background: rgba(255, 255, 255, 0.2);
}

.feature-item .el-icon {
  font-size: 24px;
  margin-top: 3px;
}

.feature-content {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.feature-title {
  font-weight: 500;
}

.feature-desc {
  font-size: 14px;
  opacity: 0.8;
}

.ai-highlight {
  margin-top: 40px;
  padding: 15px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  animation: slideInLeft 0.8s ease-out 1s backwards;
}

.highlight-text {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 16px;
}

.highlight-icon {
  font-size: 20px;
}

.login-right {
  flex: 1;
  padding: 40px;
  display: flex;
  align-items: center;
}

.login-form {
  width: 100%;
}

.login-title {
  font-size: 32px;
  color: #00796b;
  margin-bottom: 10px;
  text-align: center;
  animation: slideInRight 0.8s ease-out;
  background: linear-gradient(45deg, #004d40, #00bfa5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.login-subtitle {
  text-align: center;
  color: #666;
  margin-bottom: 30px;
  animation: slideInRight 0.8s ease-out 0.2s backwards;
}

.form-options {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  animation: slideInRight 0.8s ease-out 0.6s backwards;
}

.animate-input {
  animation: slideInRight 0.8s ease-out backwards;
}

.animate-input:nth-child(1) {
  animation-delay: 0.4s;
}

.animate-input:nth-child(2) {
  animation-delay: 0.6s;
}

.animate-button {
  animation: slideInRight 0.8s ease-out backwards;
}

.animate-button:nth-child(1) {
  animation-delay: 0.8s;
}

.animate-button:nth-child(2) {
  animation-delay: 1s;
}

@keyframes slideInLeft {
  from {
    opacity: 0;
    transform: translateX(-30px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideInRight {
  from {
    opacity: 0;
    transform: translateX(30px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.button-group {
  display: flex;
  flex-direction: column;
  width: 100%;
  animation: slideInRight 0.8s ease-out 0.8s backwards;
  margin-top: 10px;
}

.login-button {
  width: 100%;
  height: 45px;
  margin-bottom: 15px;
  background: linear-gradient(135deg, #00bfa5 0%, #00796b 100%);
  border: none;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
}

.button-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.login-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.2), transparent);
  transition: 0.5s;
}

.login-button:hover::before {
  left: 100%;
}

.login-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(0, 191, 165, 0.4);
}

.register-button {
  width: 100%;
  height: 45px;
  border: 1px solid #00796b;
  color: #00796b;
  transition: all 0.3s ease;
  border-radius: 8px;
}

.register-button:hover {
  background: #00796b;
  color: white;
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(0, 121, 107, 0.4);
}

:deep(.el-input__wrapper) {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.05);
  height: 45px;
  transition: all 0.3s ease;
}

:deep(.el-input__wrapper:hover) {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  transform: translateY(-2px);
}

:deep(.el-input__inner) {
  height: 45px;
}

.dna-helix {
  position: absolute;
  width: 100%;
  height: 100%;
}

.dna-strand {
  position: absolute;
  width: 100%;
  height: 15px;
  background: linear-gradient(90deg, transparent 30%, rgba(0, 255, 255, 0.2) 40%, rgba(0, 255, 255, 0.3) 50%, rgba(0, 255, 255, 0.2) 60%, transparent 70%);
  animation: dnaMove 15s infinite linear;
  left: -100%;
}

@keyframes dnaMove {
  0% {
    transform: translateX(0) rotateZ(0deg);
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    transform: translateX(200%) rotateZ(5deg);
    opacity: 0;
  }
}

.medical-icons {
  position: absolute;
  width: 100%;
  height: 100%;
}

.medical-icon {
  position: absolute;
  width: 40px;
  height: 40px;
  background-size: contain;
  background-repeat: no-repeat;
  opacity: 0.6;
  animation: pulseIcon 4s infinite ease-in-out;
}

.heart-beat {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23ffffff"><path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"/></svg>');
}

.pill {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23ffffff"><path d="M19 3h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm0 4c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm6 12H6v-1.4c0-2 4-3.1 6-3.1s6 1.1 6 3.1V19z"/></svg>');
}

.stethoscope {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23ffffff"><path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"/></svg>');
}

.pulse {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23ffffff"><path d="M3.5 18.49l6-6.01 4 4L22 6.92l-1.41-1.41-7.09 7.97-4-4L2 16.99z"/></svg>');
}

.medical-cross {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23ffffff"><path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"/></svg>');
}

@keyframes pulseIcon {
  0% {
    transform: scale(1);
    opacity: 0.4;
  }
  50% {
    transform: scale(1.2);
    opacity: 0.8;
  }
  100% {
    transform: scale(1);
    opacity: 0.4;
  }
}

.molecule-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.4;
}

.molecule {
  position: absolute;
  width: 120px;
  height: 120px;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" fill="none" stroke="%23E0F7FA"><circle cx="50" cy="50" r="10" fill="%2380DEEA" stroke-width="1"/><circle cx="50" cy="20" r="6" fill="%2380DEEA" stroke-width="1"/><circle cx="80" cy="50" r="6" fill="%2380DEEA" stroke-width="1"/><circle cx="50" cy="80" r="6" fill="%2380DEEA" stroke-width="1"/><circle cx="20" cy="50" r="6" fill="%2380DEEA" stroke-width="1"/><line x1="50" y1="40" x2="50" y2="26" stroke-width="2"/><line x1="60" y1="50" x2="74" y2="50" stroke-width="2"/><line x1="50" y1="60" x2="50" y2="74" stroke-width="2"/><line x1="40" y1="50" x2="26" y2="50" stroke-width="2"/></svg>');
  background-size: contain;
  background-repeat: no-repeat;
  animation: rotateMolecule 20s infinite linear;
  opacity: 0.7;
}

@keyframes rotateMolecule {
  0% {
    transform: rotate(0deg) scale(1);
  }
  50% {
    transform: rotate(180deg) scale(1.2);
  }
  100% {
    transform: rotate(360deg) scale(1);
  }
}

.ecg-bg {
  position: absolute;
  bottom: 150px;
  left: 0;
  width: 100%;
  height: 60px;
  overflow: hidden;
  opacity: 0.4;
}

.ecg-line {
  position: absolute;
  width: 200%;
  height: 100%;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="1200" height="60" viewBox="0 0 1200 60" fill="none"><path d="M0 30 L30 30 L40 10 L50 50 L60 10 L70 50 L80 30 L120 30 L130 10 L140 50 L150 30 L200 30 L210 10 L220 50 L230 10 L240 50 L250 30 L300 30 L310 10 L320 50 L330 30 L400 30 L410 10 L420 50 L430 10 L440 50 L450 30 L500 30 L510 10 L520 50 L530 30 L600 30 L610 10 L620 50 L630 10 L640 50 L650 30 L700 30 L710 10 L720 50 L730 30 L800 30 L810 10 L820 50 L830 10 L840 50 L850 30 L900 30 L910 10 L920 50 L930 30 L1000 30 L1010 10 L1020 50 L1030 10 L1040 50 L1050 30 L1100 30 L1110 10 L1120 50 L1130 30" stroke="%2380DEEA" stroke-width="2"/></svg>');
  animation: ecgMove 15s infinite linear;
}

@keyframes ecgMove {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

.medical-patterns {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.2;
}

.pattern {
  position: absolute;
  opacity: 0.3;
  animation: patternPulse 8s infinite ease-in-out;
}

.pattern-1 {
  top: 15%;
  left: 10%;
  width: 200px;
  height: 200px;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="200" height="200" viewBox="0 0 200 200"><rect x="40" y="40" width="120" height="120" rx="10" fill="none" stroke="%23B2EBF2" stroke-width="2"/><circle cx="100" cy="100" r="40" fill="none" stroke="%23B2EBF2" stroke-width="2"/><path d="M100 60 L100 140" stroke="%23B2EBF2" stroke-width="2"/><path d="M60 100 L140 100" stroke="%23B2EBF2" stroke-width="2"/></svg>');
  animation-delay: 0s;
}

.pattern-2 {
  top: 60%;
  right: 10%;
  width: 180px;
  height: 180px;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="180" height="180" viewBox="0 0 180 180"><circle cx="90" cy="90" r="80" fill="none" stroke="%23B2EBF2" stroke-width="2"/><circle cx="90" cy="90" r="60" fill="none" stroke="%23B2EBF2" stroke-width="2"/><circle cx="90" cy="90" r="40" fill="none" stroke="%23B2EBF2" stroke-width="2"/><circle cx="90" cy="90" r="20" fill="none" stroke="%23B2EBF2" stroke-width="2"/></svg>');
  animation-delay: 2s;
}

.pattern-3 {
  top: 30%;
  right: 30%;
  width: 160px;
  height: 160px;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="160" height="160" viewBox="0 0 160 160"><path d="M80 40 L120 80 L80 120 L40 80 Z" fill="none" stroke="%23B2EBF2" stroke-width="2"/><path d="M40 40 L120 120" stroke="%23B2EBF2" stroke-width="2"/><path d="M120 40 L40 120" stroke="%23B2EBF2" stroke-width="2"/><circle cx="80" cy="80" r="20" fill="none" stroke="%23B2EBF2" stroke-width="2"/></svg>');
  animation-delay: 4s;
}

@keyframes patternPulse {
  0% {
    transform: scale(1) rotate(0deg);
    opacity: 0.2;
  }
  50% {
    transform: scale(1.1) rotate(5deg);
    opacity: 0.4;
  }
  100% {
    transform: scale(1) rotate(0deg);
    opacity: 0.2;
  }
}

.glow-effect {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle at 70% 60%, rgba(0, 255, 255, 0.1) 0%, rgba(0, 0, 0, 0) 50%);
  animation: glowPulse 8s infinite ease-in-out;
}

@keyframes glowPulse {
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 0.3;
  }
}

.floating-elements {
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.medical-element {
  position: absolute;
  width: 50px;
  height: 50px;
  background-size: contain;
  background-repeat: no-repeat;
  opacity: 0.4;
  animation: floatElement 20s infinite ease-in-out;
}

.pill-icon {
  top: 25%;
  left: 15%;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="%23B2EBF2"><path d="M35 15 L15 35 C12 38, 8 38, 5 35 C2 32, 2 28, 5 25 L25 5 C28 2, 32 2, 35 5 C38 8, 38 12, 35 15 Z" fill-opacity="0.5" stroke="%23B2EBF2" stroke-width="1" /><path d="M20 20 L30 30" stroke="white" stroke-width="2"/></svg>');
  animation-delay: 0s;
}

.syringe-icon {
  top: 70%;
  right: 20%;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="%23B2EBF2"><rect x="15" y="15" width="25" height="10" rx="2" fill-opacity="0.5" stroke="%23B2EBF2" stroke-width="1"/><path d="M15 20 L5 20" stroke="%23B2EBF2" stroke-width="1"/><rect x="40" y="17" width="8" height="6" rx="1" fill-opacity="0.5" stroke="%23B2EBF2" stroke-width="1"/><path d="M18 15 L18 25 M23 15 L23 25 M28 15 L28 25 M33 15 L33 25" stroke="white" stroke-width="1"/></svg>');
  animation-delay: 3s;
}

.clipboard-icon {
  top: 40%;
  left: 80%;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="%23B2EBF2"><rect x="10" y="5" width="30" height="40" rx="2" fill-opacity="0.5" stroke="%23B2EBF2" stroke-width="1"/><rect x="15" y="3" width="20" height="5" rx="2" fill-opacity="0.5" stroke="%23B2EBF2" stroke-width="1"/><path d="M15 15 L35 15 M15 25 L35 25 M15 35 L25 35" stroke="white" stroke-width="1"/></svg>');
  animation-delay: 6s;
}

.dna-icon {
  top: 15%;
  right: 30%;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="%23B2EBF2"><path d="M20 5 C40 15, 10 35, 30 45" stroke="%23B2EBF2" stroke-width="2" fill="none"/><path d="M30 5 C10 15, 40 35, 20 45" stroke="%23B2EBF2" stroke-width="2" fill="none"/><path d="M20 10 L30 10 M20 20 L30 20 M20 30 L30 30 M20 40 L30 40" stroke="white" stroke-width="1"/></svg>');
  animation-delay: 9s;
}

.microscope-icon {
  top: 60%;
  left: 30%;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="%23B2EBF2"><path d="M25 15 L25 30" stroke="%23B2EBF2" stroke-width="2"/><rect x="20" y="30" width="10" height="5" fill-opacity="0.5" stroke="%23B2EBF2" stroke-width="1"/><path d="M15 40 L35 40" stroke="%23B2EBF2" stroke-width="2"/><path d="M23 15 L27 15" stroke="%23B2EBF2" stroke-width="2"/><circle cx="25" cy="10" r="5" fill-opacity="0.5" stroke="%23B2EBF2" stroke-width="1"/></svg>');
  animation-delay: 12s;
}

@keyframes floatElement {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }
  25% {
    transform: translate(10px, -10px) rotate(5deg);
  }
  50% {
    transform: translate(0, -20px) rotate(0deg);
  }
  75% {
    transform: translate(-10px, -10px) rotate(-5deg);
  }
  100% {
    transform: translate(0, 0) rotate(0deg);
  }
}

.footer-info {
  position: absolute;
  bottom: 15px;
  left: 0;
  width: 100%;
  text-align: center;
  color: rgba(255, 255, 255, 0.7);
  font-size: 12px;
  letter-spacing: 1px;
  animation: fadeIn 1.5s ease-out backwards;
  animation-delay: 1.2s;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
}
</style>
