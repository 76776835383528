import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../view/Login'
import Register from '../view/Register'
import Home from '../view/Home.vue'

import AI from '../view/AIBox.vue'

// 安装路由
Vue.use(VueRouter);

// 配置导出路由
const router = new VueRouter({
    routes: [{
        path: "/",
        redirect: "/login"
    },
        {
            name: "Login",
            path: '/login',
            component: Login
        },
        {
            name: "Register",
            path: '/register',
            component: Register
        },

        {
            name: "Home",
            path: '/home',
            component: Home,
            meta: { requiresAuth: true },
            children: [
                {
                    name: "Chat",
                    path: "/home/chat",
                    component: () => import("../view/Chat"),
                },
                {
                    name: "Friends",
                    path: "/home/friend",
                    component: () => import("../view/Friend"),
                },
                {
                    name: "Friends",
                    path: "/home/group",
                    component: () => import("../view/Group"),
                },

                {
                    name: "AI",
                    path: "/home/ai",
                    component: () => import("../view/AIBox.vue"),
                },

                {
                    name: "video",
                    path: "/home/video",
                    component: () => import("../view/Video"),
                    children: [
                        {
                            name: "discover",
                            path: "/home/video/discover",
                            component: () => import("../view/discover/Discover"),
                        },
                        {
                            name: "follow",
                            path: "/home/video/follow",
                            component: () => import("../view/follow/Follow"),
                        },
                        {
                            name: "hot",
                            path: "/home/video/hot",
                            component: () => import("../view/hot/Hot"),
                        },
                        {
                            name: "recommend",
                            path: "/home/video/recommend",
                            component: () => import("../view/index/Recommend"),
                        },
                    ]
                },

                {
                    name: "shop",
                    path: "/home/shop",
                    component: () => import("../view/Shop"),
                }
            ]
        }
    ]
});

// 路由导航守卫
router.beforeEach((to, from, next) => {
    // 判断该路由是否需要登录权限
    if (to.matched.some(record => record.meta.requiresAuth)) {
        // 判断用户是否已经登录
        const token = localStorage.getItem('token') || sessionStorage.getItem('accessToken')
        if (!token) {
            // 未登录，跳转到登录页面，并携带目标路由的地址
            next({
                path: '/login',
                query: { redirect: to.fullPath }
            })
        } else {
            // 已登录，直接进入目标页面
            next()
        }
    } else {
        // 不需要登录权限的页面直接进入
        next()
    }
})

export default router;