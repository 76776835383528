<template>
	<el-dialog class="setting" title="设置" :visible.sync="visible"  width="500px" :before-close="onClose">
		<el-form :model="userInfo" label-width="70px" :rules="rules" ref="settingForm">
			<el-form-item label="头像">
				<file-upload  class="avatar-uploader"
				:action="imageAction" 
				:showLoading="true"
				:maxSize="maxSize"  
				@success="onUploadSuccess"
				:fileTypes="['image/jpeg', 'image/png', 'image/jpg','image/webp']">
					<img v-if="userInfo.headImage" :src="userInfo.headImage" class="avatar">
					<i v-else class="el-icon-plus avatar-uploader-icon"></i>
				</file-upload>
			</el-form-item>
			<el-form-item label="用户名">
				<el-input disabled v-model="userInfo.userName" autocomplete="off"></el-input>
			</el-form-item>
			<el-form-item prop="nickName" label="昵称">
				<el-input v-model="userInfo.nickName" autocomplete="off"></el-input>
			</el-form-item>
			<el-form-item label="性别">
				<el-radio-group v-model="userInfo.sex">
					<el-radio :label="0">男</el-radio>
					<el-radio :label="1">女</el-radio>
				</el-radio-group>
			</el-form-item>
			<el-form-item label="个性签名">
				<el-input type="textarea" v-model="userInfo.signature"></el-input>
			</el-form-item>
		</el-form>

		<span slot="footer" class="dialog-footer">
			<el-button @click="onClose()">取 消</el-button>
			<el-button type="primary" @click="onSubmit()">确 定</el-button>
		</span>
	</el-dialog>
</template>

<script>
	import FileUpload from "../common/FileUpload.vue";
	
	export default {
		name: "setting",
		components: {
			FileUpload
		},
		data() {
			return {
				userInfo: {

				},
				maxSize: 5*1024*1024,
				action: "/image/upload",
				rules: {
					nickName: [{
						required: true,
						message: '请输入昵称',
						trigger: 'blur'
					}]
				}
			}
		},
		methods: {

			onClose() {
				this.$emit("close");
			},
			onSubmit() {
				this.$refs['settingForm'].validate((valid) => {
					if (!valid) {
						return false;
					}
					this.$http({
						url: "/user/update",
						method: "put",
						data: this.userInfo
					}).then(()=>{
						this.$store.commit("setUserInfo",this.userInfo);
						this.$emit("close");
						this.$message.success("修改成功");
					})	
				});
			},
			onUploadSuccess(data, file) {
				this.userInfo.headImage = data.originUrl;
				this.userInfo.headImageThumb = data.thumbUrl;
			}
		},
		props: {
			visible: {
				type: Boolean
			}
		},
		computed:{
			imageAction(){
				return `/image/upload`;
			}
		},
		watch: {
			visible: function(newData, oldData) {
				// 深拷贝
				let mine = this.$store.state.userStore.userInfo;
				this.userInfo = JSON.parse(JSON.stringify(mine));
			}
		}
	}
</script>

<style lang="scss" >
	.setting {
		// 对话框动画
		.el-dialog__wrapper {
			animation: dialogFadeIn 0.3s ease-out;
		}
		
		.el-dialog {
			border-radius: 16px;
			overflow: hidden;
			box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
			border: none;
			transform-origin: center;
			animation: dialogZoomIn 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
			
			&::before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 4px;
				background: linear-gradient(90deg, #6e8efb, #7367f0);
				z-index: 1;
			}
		}
		
		.el-dialog__header {
			background: linear-gradient(120deg, #6e8efb, #7367f0, #587FF0);
			background-size: 200% 200%;
			animation: gradientAnimation 10s ease infinite;
			padding: 20px;
			position: relative;
			
			.el-dialog__title {
				color: white;
				font-size: 20px;
				font-weight: 600;
				letter-spacing: 1px;
			}
			
			.el-dialog__headerbtn .el-dialog__close {
				color: white;
				transition: all 0.3s ease;
				
				&:hover {
					transform: rotate(90deg);
				}
			}
		}
		
		.el-dialog__body {
			padding: 30px;
			background-color: #f9fafc;
		}
		
		.el-dialog__footer {
			padding: 15px 30px 25px;
			background-color: #f9fafc;
			border-top: 1px solid rgba(0, 0, 0, 0.05);
		}
		
		.el-form-item {
			margin-bottom: 22px;
			transition: all 0.3s ease;
			
			&:hover {
				transform: translateX(5px);
			}
		}
		
		.el-form-item__label {
			font-weight: 600;
			color: #606266;
		}
		
		.el-input__inner {
			border-radius: 10px;
			border: 1px solid #e0e7ff;
			transition: all 0.3s ease;
			
			&:hover, &:focus {
				border-color: #6e8efb;
			}
			
			&:focus {
				box-shadow: 0 0 0 3px rgba(110, 142, 251, 0.15);
			}
			
			&[disabled] {
				background-color: #f5f7fa;
				border-color: #e4e7ed;
				color: #909399;
				cursor: not-allowed;
			}
		}
		
		.el-textarea__inner {
			border-radius: 10px;
			border: 1px solid #e0e7ff;
			transition: all 0.3s ease;
			min-height: 80px;
			
			&:hover, &:focus {
				border-color: #6e8efb;
			}
			
			&:focus {
				box-shadow: 0 0 0 3px rgba(110, 142, 251, 0.15);
			}
		}
		
		.el-radio {
			margin-right: 20px;
			transition: all 0.3s ease;
			
			&:hover {
				transform: scale(1.05);
			}
		}
		
		.el-radio__input.is-checked .el-radio__inner {
			background-color: #6e8efb;
			border-color: #6e8efb;
			box-shadow: 0 0 5px rgba(110, 142, 251, 0.3);
		}
		
		.el-radio__input.is-checked + .el-radio__label {
			color: #6e8efb;
		}
		
		.el-button {
			border-radius: 12px;
			padding: 12px 24px;
			transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
			font-weight: 600;
			letter-spacing: 0.5px;
			
			&.el-button--default {
				border: 1px solid #dcdfe6;
				
				&:hover {
					border-color: #c0c4cc;
					color: #606266;
					background-color: #f5f7fa;
					transform: translateY(-2px);
				}
				
				&:active {
					transform: translateY(0);
				}
			}
			
			&.el-button--primary {
				background: linear-gradient(120deg, #6e8efb, #7367f0);
				border: none;
				box-shadow: 0 5px 15px rgba(110, 142, 251, 0.4);
				
				&:hover {
					box-shadow: 0 8px 20px rgba(110, 142, 251, 0.5);
					transform: translateY(-3px);
				}
				
				&:active {
					transform: translateY(-1px);
				}
			}
		}
		
		.avatar-uploader {
			.el-upload {
				border: 2px dashed rgba(110, 142, 251, 0.3) !important;
				border-radius: 16px !important;
				cursor: pointer;
				position: relative;
				overflow: hidden;
				transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
				
				&:hover {
					border-color: #6e8efb !important;
					transform: translateY(-5px);
					box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
				}
			}

			.avatar-uploader-icon {
				font-size: 32px;
				color: #6e8efb;
				width: 178px;
				height: 178px;
				line-height: 178px;
				text-align: center;
				transition: all 0.3s ease;
				
				&:hover {
					transform: rotate(10deg);
				}
			}

			.avatar {
				width: 178px;
				height: 178px;
				display: block;
				border-radius: 16px;
				transition: all 0.3s ease;
				object-fit: cover;
				box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
				
				&:hover {
					transform: scale(1.05);
				}
			}
		}
	}
	
	// 渐变背景动画
	@keyframes gradientAnimation {
		0% {
			background-position: 0% 50%;
		}
		50% {
			background-position: 100% 50%;
		}
		100% {
			background-position: 0% 50%;
		}
	}
	
	// 对话框入场动画
	@keyframes dialogZoomIn {
		0% {
			opacity: 0;
			transform: scale(0.95);
		}
		100% {
			opacity: 1;
			transform: scale(1);
		}
	}
	
	@keyframes dialogFadeIn {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}
	
	// 修改成功消息效果
	.el-message {
		border-radius: 12px;
		padding: 12px 20px;
		box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
		border: none;
		
		&.el-message--success {
			background: linear-gradient(120deg, #36d1dc, #5b86e5);
			
			.el-message__content, .el-icon-success {
				color: white;
			}
		}
		
		&.el-message--warning {
			background: linear-gradient(120deg, #f2994a, #f2c94c);
			
			.el-message__content, .el-icon-warning {
				color: white;
			}
		}
		
		&.el-message--error {
			background: linear-gradient(120deg, #ff416c, #ff4b2b);
			
			.el-message__content, .el-icon-error {
				color: white;
			}
		}
	}
</style>
