<template>
  <div class="ai-chat-container">
    <!-- 侧边栏 -->
    <div class="ai-chat-sidebar" :class="{ 'sidebar-collapsed': sidebarCollapsed }">
      <div class="sidebar-header">
        <div class="sidebar-title">对话历史</div>
        <i class="sidebar-toggle el-icon-d-arrow-left" @click="toggleSidebar"></i>
      </div>

      <div class="sidebar-actions">
        <el-button type="primary" size="mini" icon="el-icon-plus" @click="startNewChat">新对话</el-button>
      </div>

      <div class="chat-history-list">
        <div
            v-for="(chat, index) in chatHistory"
            :key="index"
            class="chat-history-item"
            :class="{ 'active': currentChatIndex === index }"
            @click="switchChat(index)">
          <div class="chat-history-icon">
            <i class="el-icon-chat-dot-round"></i>
          </div>
          <div class="chat-history-info">
            <div class="chat-history-title">{{ chat.title || '新对话' }}</div>
            <div class="chat-history-time">{{ chat.time }}</div>
          </div>
          <div class="chat-history-actions">
            <i class="el-icon-delete" @click.stop="deleteChat(index)"></i>
          </div>
        </div>
      </div>
    </div>

    <!-- 主要内容区 -->
    <div class="ai-chat-main">
      <div class="ai-chat-header">
        <div class="header-title">
          <i class="header-icon el-icon-s-platform"></i>
          <span>智能助手</span>
        </div>
        <div class="header-actions">
          <el-tooltip content="清空对话" placement="bottom">
            <el-button type="text" icon="el-icon-delete" @click="clearMessages"></el-button>
          </el-tooltip>
        </div>
      </div>

      <div class="ai-chat-body" ref="chatBody">
        <ai-background></ai-background>

        <div class="message-container">
          <template v-if="chatMessages.length === 0">
            <div class="welcome-container">
              <div class="welcome-title">欢迎使用AI助手</div>
              <div class="welcome-desc">我可以回答您的问题，请在下方输入您的问题</div>
              <div class="feature-list">
                <div class="feature-item" v-for="(item, index) in features" :key="index">
                  <i class="el-icon-check"></i>
                  <span>{{ item }}</span>
                </div>
              </div>
            </div>
          </template>

          <template v-else>
            <ai-message
                v-for="(msg, index) in chatMessages"
                :key="index"
                :message="msg"
                :index="index">
            </ai-message>
          </template>

          <div v-if="isTyping" class="typing-indicator">
            <ai-typing></ai-typing>
          </div>
        </div>
      </div>

      <div class="ai-chat-footer">
        <div class="input-container">
          <div class="input-background"></div>
          <el-input
              v-model="userInput"
              type="textarea"
              :rows="2"
              placeholder="请输入您的问题..."
              @keyup.enter.native="sendMessage"
              :disabled="isTyping"
              class="chat-input"
          ></el-input>
          <div class="input-effects">
            <span class="input-dot" v-for="n in 5" :key="n"></span>
          </div>
        </div>
        <el-button
            type="primary"
            icon="el-icon-s-promotion"
            class="send-btn"
            :loading="isTyping"
            @click="sendMessage">发送
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import AiMessage from './AIMessage.vue'
import AiTyping from './AITyping.vue'
import AiBackground from './AIBackground.vue'
import {aiChat} from '@/api/ai'

export default {
  name: 'AiChat',
  components: {
    AiMessage,
    AiTyping,
    AiBackground
  },
  data() {
    return {
      userInput: '',
      chatMessages: [],
      isTyping: false,
      sidebarCollapsed: false,
      chatHistory: [],
      currentChatIndex: -1,
      features: [
        '回答各种问题',
        '提供专业建议',
        '帮助解决问题',
        '学习新知识'
      ]
    }
  },
  created() {
    // 从本地存储加载聊天历史
    this.loadChatHistory()
    // 如果有聊天历史，加载最近的一个对话
    if (this.chatHistory.length > 0) {
      this.switchChat(0)
    } else {
      // 否则创建新对话
      this.startNewChat()
    }
  },
  methods: {
    sendMessage() {
      if (!this.userInput.trim() || this.isTyping) return
      // 添加用户消息
      this.chatMessages.push({
        type: 'user',
        content: this.userInput,
        time: new Date().toLocaleTimeString()
      })
      const userQuestion = this.userInput
      this.userInput = ''
      // 滚动到底部
      this.$nextTick(() => {
        this.scrollToBottom()
      })
      // 调用AI聊天API
      this.isTyping = true
      // 获取当前会话ID或创建新的会话ID
      const sessionId = this.chatHistory[this.currentChatIndex].sessionId || this.generateSessionId()
      // 保存会话ID
      if (!this.chatHistory[this.currentChatIndex].sessionId) {
        this.chatHistory[this.currentChatIndex].sessionId = sessionId
        this.saveChatHistory()
      }
      aiChat({
        message: userQuestion,
        sessionId: sessionId
      }).then(response => {
        // 添加调试日志
        console.log('AI响应成功:', response)
        
        // 检查响应数据格式
        if (response && response.message) {
          // 添加AI响应
          this.chatMessages.push({
            type: 'ai',
            content: response.message,
            time: new Date().toLocaleTimeString()
          })
          // 更新聊天历史
          this.updateChatHistory(userQuestion, response.message)
        } else {
          console.error('响应格式异常:', response)
          // 添加错误提示
          this.chatMessages.push({
            type: 'ai',
            content: '响应格式异常，请检查控制台日志',
            time: new Date().toLocaleTimeString()
          })
        }
      }).catch(error => {
        // 添加调试日志
        console.error('AI请求失败:', error)
        
        // 处理错误情况
        this.chatMessages.push({
          type: 'ai',
          content: '很抱歉，我暂时无法回答您的问题，请稍后再试。',
          time: new Date().toLocaleTimeString()
        })
      }).finally(() => {
        this.isTyping = false
        // 滚动到底部
        this.$nextTick(() => {
          this.scrollToBottom()
        })
      })
    },
    scrollToBottom() {
      const chatBody = this.$refs.chatBody
      chatBody.scrollTop = chatBody.scrollHeight
    },
    clearMessages() {
      this.chatMessages = []
      this.updateCurrentChat()
    },
    toggleSidebar() {
      this.sidebarCollapsed = !this.sidebarCollapsed
    },
    startNewChat() {
      // 保存当前对话
      if (this.currentChatIndex !== -1 && this.chatMessages.length > 0) {
        this.updateCurrentChat()
      }
      // 创建新对话
      this.chatMessages = []
      const newChat = {
        title: '新对话',
        time: new Date().toLocaleString(),
        messages: [],
        sessionId: this.generateSessionId()
      }
      this.chatHistory.unshift(newChat)
      this.currentChatIndex = 0
      this.saveChatHistory()
    },
    switchChat(index) {
      // 保存当前对话
      if (this.currentChatIndex !== -1) {
        this.updateCurrentChat()
      }
      // 切换到选择的对话
      this.currentChatIndex = index
      this.chatMessages = [...this.chatHistory[index].messages]
      // 滚动到底部
      this.$nextTick(() => {
        this.scrollToBottom()
      })
    },
    deleteChat(index) {
      // 如果删除的是当前对话
      if (index === this.currentChatIndex) {
        this.chatHistory.splice(index, 1)
        // 如果还有其他对话，切换到第一个
        if (this.chatHistory.length > 0) {
          this.currentChatIndex = 0
          this.chatMessages = [...this.chatHistory[0].messages]
        } else {
          // 否则创建新对话
          this.startNewChat()
        }
      } else {
        // 如果删除的不是当前对话，更新索引
        this.chatHistory.splice(index, 1)
        if (index < this.currentChatIndex) {
          this.currentChatIndex--
        }
      }

      this.saveChatHistory()
    },
    updateCurrentChat() {
      if (this.currentChatIndex !== -1) {
        // 更新当前对话
        this.chatHistory[this.currentChatIndex].messages = [...this.chatMessages]

        // 如果有消息，则使用第一条用户消息作为标题
        if (this.chatMessages.length > 0) {
          const firstUserMessage = this.chatMessages.find(msg => msg.type === 'user')
          if (firstUserMessage) {
            // 截取前20个字符作为标题
            this.chatHistory[this.currentChatIndex].title = firstUserMessage.content.substring(0, 20) + (firstUserMessage.content.length > 20 ? '...' : '')
          }
        }

        this.saveChatHistory()
      }
    },
    updateChatHistory(question, answer) {
      // 更新对话标题（仅当是第一条消息时）
      if (this.chatMessages.length <= 2 && this.currentChatIndex !== -1) {
        this.chatHistory[this.currentChatIndex].title = question.substring(0, 20) + (question.length > 20 ? '...' : '')
      }

      this.updateCurrentChat()
    },
    loadChatHistory() {
      const savedHistory = localStorage.getItem('aiChatHistory')
      if (savedHistory) {
        try {
          this.chatHistory = JSON.parse(savedHistory)
        } catch (error) {
          console.error('加载聊天历史失败', error)
          this.chatHistory = []
        }
      }
    },
    saveChatHistory() {
      try {
        localStorage.setItem('aiChatHistory', JSON.stringify(this.chatHistory))
      } catch (error) {
        console.error('保存聊天历史失败', error)
      }
    },
    generateSessionId() {
      // 生成唯一的会话ID
      return 'session_' + Date.now() + '_' + Math.random().toString(36).substr(2, 9)
    }
  }
}
</script>

<style scoped>
.ai-chat-container {
  height: 100%;
  display: flex;
  background-color: rgba(16, 24, 39, 0.95);
  border-radius: 12px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  position: relative;
}

/* 侧边栏样式 */
.ai-chat-sidebar {
  width: 260px;
  background: linear-gradient(180deg, #161f2e, #1c2537);
  border-right: 1px solid rgba(100, 116, 139, 0.2);
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;
  overflow: hidden;
}

.sidebar-collapsed {
  width: 0;
}

.sidebar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid rgba(100, 116, 139, 0.2);
}

.sidebar-title {
  font-size: 16px;
  font-weight: 600;
  color: #e2e8f0;
}

.sidebar-toggle {
  color: #94a3b8;
  cursor: pointer;
  transition: all 0.3s ease;
}

.sidebar-toggle:hover {
  color: #4a9eff;
}

.sidebar-actions {
  padding: 12px 16px;
  border-bottom: 1px solid rgba(100, 116, 139, 0.1);
}

.chat-history-list {
  flex: 1;
  overflow-y: auto;
  padding: 8px;
}

.chat-history-item {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
  position: relative;
}

.chat-history-item:hover {
  background-color: rgba(74, 158, 255, 0.1);
}

.chat-history-item.active {
  background-color: rgba(74, 158, 255, 0.15);
  border: 1px solid rgba(74, 158, 255, 0.3);
}

.chat-history-icon {
  width: 32px;
  height: 32px;
  background: linear-gradient(135deg, #3178c6, #4a9eff);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.chat-history-icon i {
  color: #fff;
  font-size: 16px;
}

.chat-history-info {
  flex: 1;
  overflow: hidden;
}

.chat-history-title {
  color: #e2e8f0;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.chat-history-time {
  color: #94a3b8;
  font-size: 12px;
  margin-top: 2px;
}

.chat-history-actions {
  opacity: 0;
  transition: opacity 0.2s ease;
}

.chat-history-item:hover .chat-history-actions {
  opacity: 1;
}

.chat-history-actions i {
  color: #94a3b8;
  padding: 4px;
}

.chat-history-actions i:hover {
  color: #ef4444;
}

/* 自定义侧边栏滚动条 */
.chat-history-list::-webkit-scrollbar {
  width: 4px;
}

.chat-history-list::-webkit-scrollbar-track {
  background: rgba(30, 41, 59, 0.2);
}

.chat-history-list::-webkit-scrollbar-thumb {
  background: rgba(74, 158, 255, 0.3);
  border-radius: 2px;
}

/* 主内容区样式 */
.ai-chat-main {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.ai-chat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px;
  background: linear-gradient(90deg, #1a1f35, #2d3a5a);
  color: #fff;
  border-bottom: 1px solid rgba(100, 116, 139, 0.2);
  z-index: 10;
}

.header-title {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
}

.header-icon {
  margin-right: 8px;
  color: #4a9eff;
  font-size: 20px;
}

.ai-chat-body {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  position: relative;
  scroll-behavior: smooth;
}

.message-container {
  position: relative;
  z-index: 2;
}

.welcome-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  padding: 40px 20px;
  text-align: left;
  animation: fadeIn 0.5s ease-in-out;
}

.welcome-title {
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 16px;
  color: #fff;
  text-shadow: 0 0 10px rgba(74, 158, 255, 0.5);
  align-self: flex-start;
}

.welcome-desc {
  font-size: 16px;
  color: #a8b2d1;
  margin-bottom: 30px;
  align-self: flex-start;
}

.feature-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 12px;
  max-width: 100%;
  align-self: flex-start;
}

.feature-item {
  background-color: rgba(74, 158, 255, 0.1);
  border: 1px solid rgba(74, 158, 255, 0.3);
  border-radius: 8px;
  padding: 10px 16px;
  display: flex;
  align-items: center;
  color: #a8b2d1;
  transition: all 0.3s ease;
}

.feature-item:hover {
  transform: translateY(-2px);
  background-color: rgba(74, 158, 255, 0.2);
  box-shadow: 0 4px 12px rgba(74, 158, 255, 0.15);
}

.feature-item i {
  color: #4a9eff;
  margin-right: 8px;
}

.ai-chat-footer {
  padding: 16px;
  background: rgba(22, 33, 54, 0.7);
  backdrop-filter: blur(10px);
  border-top: 1px solid rgba(100, 116, 139, 0.2);
  display: flex;
  z-index: 10;
}

/* 新的输入框样式 */
.input-container {
  flex: 1;
  position: relative;
  border-radius: 12px;
  overflow: hidden;
}

.input-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, rgba(30, 41, 59, 0.8), rgba(30, 41, 59, 0.9));
  border-radius: 12px;
  z-index: 1;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.input-background::before {
  content: '';
  position: absolute;
  width: 150%;
  height: 150%;
  background: linear-gradient(
      45deg,
      transparent 20%,
      rgba(74, 158, 255, 0.1) 40%,
      rgba(74, 158, 255, 0.1) 60%,
      transparent 80%
  );
  top: -25%;
  left: -25%;
  animation: shineEffect 3s infinite linear;
}

.chat-input {
  position: relative;
  z-index: 2;
  background: transparent;
}

.chat-input >>> .el-textarea__inner {
  background-color: transparent !important;
  border: none !important;
  color: #fff;
  padding: 12px 16px;
  font-size: 15px;
  resize: none;
  height: 100%;
  box-shadow: none !important;
  transition: all 0.3s ease;
}

.chat-input >>> .el-textarea__inner:focus {
  border: none !important;
  box-shadow: none !important;
}

.chat-input >>> .el-textarea__inner::placeholder {
  color: rgba(148, 163, 184, 0.7);
}

.input-effects {
  position: absolute;
  bottom: 8px;
  right: 12px;
  z-index: 2;
  display: flex;
}

.input-dot {
  width: 4px;
  height: 4px;
  background-color: rgba(74, 158, 255, 0.5);
  border-radius: 50%;
  margin: 0 2px;
  opacity: 0;
}

.input-dot:nth-child(1) {
  animation: pulseDot 2s infinite 0.2s;
}

.input-dot:nth-child(2) {
  animation: pulseDot 2s infinite 0.4s;
}

.input-dot:nth-child(3) {
  animation: pulseDot 2s infinite 0.6s;
}

.input-dot:nth-child(4) {
  animation: pulseDot 2s infinite 0.8s;
}

.input-dot:nth-child(5) {
  animation: pulseDot 2s infinite 1s;
}

.send-btn {
  margin-left: 12px;
  height: 44px;
  align-self: center;
  background: linear-gradient(90deg, #4a9eff, #3178c6);
  border: none;
  border-radius: 10px;
  transition: all 0.3s ease;
  box-shadow: 0 4px 12px rgba(74, 158, 255, 0.2);
  padding: 0 20px;
}

.send-btn:hover {
  background: linear-gradient(90deg, #3178c6, #4a9eff);
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(74, 158, 255, 0.3);
}

.typing-indicator {
  padding: 10px;
  margin-top: 16px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes shineEffect {
  0% {
    transform: translateX(-100%) translateY(-100%) rotate(45deg);
  }
  100% {
    transform: translateX(100%) translateY(100%) rotate(45deg);
  }
}

@keyframes pulseDot {
  0%, 100% {
    transform: scale(1);
    opacity: 0.3;
  }
  50% {
    transform: scale(1.5);
    opacity: 0.7;
  }
}

/* 适配深色主题 */
:root {
  --ai-primary: #4a9eff;
  --ai-primary-hover: #3178c6;
  --ai-bg-dark: #101827;
  --ai-bg-message: #1e293b;
  --ai-text-light: #e2e8f0;
  --ai-text-muted: #94a3b8;
}

/* 自定义滚动条 */
.ai-chat-body::-webkit-scrollbar {
  width: 6px;
}

.ai-chat-body::-webkit-scrollbar-track {
  background: rgba(30, 41, 59, 0.5);
}

.ai-chat-body::-webkit-scrollbar-thumb {
  background: rgba(74, 158, 255, 0.5);
  border-radius: 3px;
}

.ai-chat-body::-webkit-scrollbar-thumb:hover {
  background: rgba(74, 158, 255, 0.7);
}
</style> 