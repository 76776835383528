<template>
    <div class="video-container">
      <!-- 顶部导航栏 -->
      <div class="video-header">
        <div class="header-left">
          <div class="logo-icon">
            <i class="el-icon-video-camera"></i>
          </div>
          <div class="header-tabs">
            <div 
              class="tab-item" 
              :class="{ active: activeTab === 'recommend' }"
              @click="switchTab('recommend')"
            >
              推荐
            </div>
            <div 
              class="tab-item" 
              :class="{ active: activeTab === 'follow' }"
              @click="switchTab('follow')"
            >
              关注
            </div>
          </div>
        </div>
        <div class="header-right">
          <div class="header-search" @click="goToSearch">
            <i class="el-icon-search"></i>
            <span>搜索视频</span>
          </div>
          <div class="upload-btn" @click="showUploadPanel = true">
            <i class="el-icon-plus"></i>
            <span>上传视频</span>
          </div>
        </div>
      </div>

      <!-- 视频网格区域 -->
      <div class="video-grid" :class="{ 'pc-mode': isPcMode }">
        <div 
          v-for="video in videoList" 
          :key="video.id" 
          class="video-card"
          @click="playVideo(video)"
          :class="{ 'hover-effect': isPcMode }"
        >
          <div class="video-cover">
            <img :src="video.cover" :alt="video.title">
            <div class="video-duration">3:45</div>
            <div class="video-overlay">
              <div class="play-icon">
                <i class="el-icon-video-play"></i>
              </div>
            </div>
          </div>
          <div class="video-info">
            <h3 class="video-title">{{ video.title }}</h3>
            <p class="video-desc">{{ video.description }}</p>
            <div class="video-author">
              <img :src="video.author.avatar" :alt="video.author.name" class="author-avatar">
              <span class="author-name">{{ video.author.name }}</span>
            </div>
            <div class="video-stats">
              <span><i class="el-icon-view"></i> {{ video.likes }}</span>
              <span><i class="el-icon-chat-dot-round"></i> {{ video.comments }}</span>
            </div>
            <div class="video-tags">
              <span v-for="tag in video.tags" :key="tag" class="tag">{{ tag }}</span>
            </div>
          </div>
        </div>
      </div>
  
      <!-- 上传面板 -->
      <video-uploader 
        v-if="showUploadPanel" 
        @close="showUploadPanel = false"
        @upload-success="handleUploadSuccess"
      />
  
      <!-- 评论面板 -->
      <video-comment 
        v-if="showCommentPanel" 
        :video="currentVideo"
        @close="showCommentPanel = false"
      />
  
      <!-- 分享面板 -->
      <video-share 
        v-if="showSharePanel" 
        :video="currentVideo"
        @close="showSharePanel = false"
      />

      <!-- 全屏视频播放组件 -->
      <video-full-screen
        :visible="showFullScreenPlayer"
        :video="currentVideo"
        @close="closeFullScreenPlayer"
        @like="handleLike"
        @comment="handleComment"
        @share="handleShare"
        @follow="handleFollow"
      />
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import VideoPlayer from '@/components/video/VideoPlayer'
import VideoUploader from '@/components/video/VideoUploader'
import VideoComment from '@/components/video/VideoComment'
import VideoShare from '@/components/video/VideoShare'
import VideoFullScreen from '@/components/video/VideoFullScreen'

export default {
  name: 'Video',
  components: {
    Swiper,
    SwiperSlide,
    VideoPlayer,
    VideoUploader,
    VideoComment,
    VideoShare,
    VideoFullScreen
  },
  data() {
    return {
      videoList: [],
      currentIndex: 0,
      activeTab: 'recommend',
      showUploadPanel: false,
      showCommentPanel: false,
      showSharePanel: false,
      showFullScreenPlayer: false,
      isPcMode: false,
      swiperOptions: {
        direction: 'vertical',
        slidesPerView: 1,
        spaceBetween: 0,
        mousewheel: true,
        observer: true,
        observeParents: true,
        effect: 'cube',
        cubeEffect: {
          shadow: true,
          slideShadows: true,
          shadowOffset: 20,
          shadowScale: 0.94,
        },
        grabCursor: true,
        centeredSlides: true,
        speed: 800,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }
      }
    }
  },
  computed: {
    currentVideo() {
      return this.videoList[this.currentIndex] || {}
    }
  },
  created() {
    this.loadVideos()
  },
  mounted() {
    this.checkDeviceMode()
    window.addEventListener('resize', this.checkDeviceMode)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkDeviceMode)
  },
  methods: {
    checkDeviceMode() {
      this.isPcMode = window.innerWidth > 768
    },
    loadVideos() {
      // 模拟加载视频数据
      this.videoList = [
        {
          id: 1,
          url: 'http://116.198.205.204:9000/yd-short/0/e/0e7ec86edadfe1582d6b88e0e57be9fa/0e7ec86edadfe1582d6b88e0e57be9fa.mp4',
          cover: 'https://picsum.photos/800/1200?random=1',
          title: '霓虹灯下的少女',
          description: '城市夜景中的一抹亮色',
          author: {
            id: 101,
            name: '摄影师小王',
            avatar: 'https://picsum.photos/100/100?random=1',
            isFollowed: false
          },
          likes: 1234,
          comments: 456,
          shares: 789,
          isLiked: false,
          tags: ['夜景', '人像', '霓虹']
        },
        {
          id: 2,
          url: 'https://assets.mixkit.co/videos/preview/mixkit-tree-with-yellow-flowers-1173-large.mp4',
          cover: 'https://picsum.photos/800/1200?random=2',
          title: '春日花语',
          description: '春天里的第一抹黄',
          author: {
            id: 102,
            name: '自然摄影师',
            avatar: 'https://picsum.photos/100/100?random=2',
            isFollowed: true
          },
          likes: 5678,
          comments: 910,
          shares: 1112,
          isLiked: true,
          tags: ['春天', '自然', '花卉']
        },
        {
          id: 3,
          url: 'https://assets.mixkit.co/videos/preview/mixkit-waves-in-the-water-1164-large.mp4',
          cover: 'https://picsum.photos/800/1200?random=3',
          title: '海浪之声',
          description: '治愈系海浪',
          author: {
            id: 103,
            name: '海洋记录者',
            avatar: 'https://picsum.photos/100/100?random=3',
            isFollowed: false
          },
          likes: 3456,
          comments: 789,
          shares: 1234,
          isLiked: false,
          tags: ['海洋', '治愈', '自然']
        },
        {
          id: 4,
          url: 'https://assets.mixkit.co/videos/preview/mixkit-woman-wearing-a-virtual-reality-headset-39774-large.mp4',
          cover: 'https://picsum.photos/800/1200?random=4',
          title: '未来科技',
          description: 'VR世界探索',
          author: {
            id: 104,
            name: '科技达人',
            avatar: 'https://picsum.photos/100/100?random=4',
            isFollowed: true
          },
          likes: 7890,
          comments: 1234,
          shares: 567,
          isLiked: false,
          tags: ['科技', 'VR', '未来']
        }
      ]
    },
    onSlideChange() {
      this.currentIndex = this.$refs.videoSwiper.$swiper.activeIndex
    },
    switchTab(tab) {
      this.activeTab = tab
      // 根据tab加载不同的视频列表
      this.loadVideos()
    },
    handleLike(videoId) {
      const video = this.videoList.find(v => v.id === videoId)
      if (video) {
        video.isLiked = !video.isLiked
        video.likes += video.isLiked ? 1 : -1
      }
    },
    goToSearch() {
      // 跳转到搜索页面
      this.$router.push('/video/search')
    },
    handleUploadSuccess(newVideo) {
      // 上传成功后添加到视频列表
      this.videoList.unshift(newVideo)
      this.showUploadPanel = false
    },
    playVideo(video) {
      // 通过找到视频的索引来设置当前索引
      const index = this.videoList.findIndex(v => v.id === video.id);
      if (index !== -1) {
        this.currentIndex = index;
      }
      this.showFullScreenPlayer = true;
    },
    closeFullScreenPlayer() {
      this.showFullScreenPlayer = false;
    },
    handleComment() {
      this.showCommentPanel = true;
    },
    handleShare() {
      this.showSharePanel = true;
    },
    handleFollow(authorId) {
      const video = this.videoList.find(v => v.author.id === authorId);
      if (video) {
        video.author.isFollowed = !video.author.isFollowed;
      }
    }
  }
}
</script>

<style scoped>
.video-container {
  width: calc(100% - 80px);
  min-height: 100vh;
  background-color: #f8f8f8;
  padding-top: 70px;
  margin-left: 80px;
}

.video-header {
  position: fixed;
  top: 0;
  left: 80px;
  width: calc(100% - 80px);
  height: 70px;
  padding: 0 20px 0 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.05);
  z-index: 100;
  border-bottom: 1px solid #eee;
}

.header-left {
  display: flex;
  align-items: center;
  gap: 80px;
}

.logo-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ff5e62;
  font-size: 24px;
  margin-left: 10px;
}

.logo-icon i {
  font-size: 26px;
}

.header-tabs {
  display: flex;
  gap: 40px;
  height: 70px;
  align-items: center;
}

.tab-item {
  font-size: 16px;
  color: #666;
  cursor: pointer;
  position: relative;
  padding: 0 5px;
  height: 70px;
  line-height: 70px;
  transition: all 0.3s ease;
}

.tab-item:hover {
  color: #333;
}

.tab-item.active {
  color: #ff5e62;
  font-weight: bold;
}

.tab-item.active::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background: #ff5e62;
}

.header-right {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-right: 80px;
  position: relative;
  right: 20px;
}

.header-search {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 0 8px;
  background: #f5f5f5;
  border-radius: 6px;
  border: 1px solid #eee;
  height: 34px;
  width: 160px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.header-search:hover {
  background: #f0f0f0;
  border-color: #ddd;
}

.header-search i {
  color: #666;
}

.header-search span {
  color: #999;
  font-size: 14px;
}

.upload-btn {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 0 10px;
  background: linear-gradient(135deg, #ff5e62, #ff9966);
  border-radius: 6px;
  height: 34px;
  color: #fff;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.upload-btn:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(255, 94, 98, 0.2);
}

.upload-btn i {
  font-size: 16px;
}

.video-grid {
  max-width: 100%;
  width: 98%;
  margin: 20px 0 20px 1%;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
  gap: 16px;
  transition: all 0.3s ease;
  justify-content: start;
}

.video-grid.pc-mode {
  grid-template-columns: repeat(5, 1fr);
  gap: 18px;
}

.video-card {
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease;
  cursor: pointer;
  position: relative;
  animation: fadeIn 0.5s ease forwards;
  opacity: 0;
}

.video-card.hover-effect:hover {
  transform: translateY(-10px) scale(1.02);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);
}

.video-cover {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  overflow: hidden;
}

.video-cover img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.video-card:hover .video-cover img {
  transform: scale(1.1);
}

.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  opacity: 0;
  transition: opacity 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-card:hover .video-overlay {
  opacity: 1;
}

.play-icon {
  width: 60px;
  height: 60px;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(0.8);
  transition: transform 0.3s ease;
}

.video-card:hover .play-icon {
  transform: scale(1);
}

.play-icon i {
  font-size: 24px;
  color: #ff5e62;
}

.video-info {
  padding: 15px;
  background: #fff;
  transition: background-color 0.3s ease;
}

.video-card:hover .video-info {
  background: #fafafa;
}

.video-title {
  margin: 0 0 8px;
  font-size: 16px;
  color: #333;
  line-height: 1.3;
  transition: color 0.3s ease;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.video-card:hover .video-title {
  color: #ff5e62;
}

.video-desc {
  margin: 0 0 12px;
  font-size: 13px;
  color: #666;
  line-height: 1.5;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.video-author {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 12px;
  transition: transform 0.3s ease;
}

.video-card:hover .video-author {
  transform: translateX(5px);
}

.author-avatar {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.author-name {
  font-size: 13px;
  color: #666;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 120px;
}

.video-stats {
  display: flex;
  gap: 15px;
  margin-bottom: 12px;
  color: #999;
  font-size: 13px;
}

.video-stats span {
  display: flex;
  align-items: center;
  gap: 6px;
  transition: color 0.3s ease;
}

.video-card:hover .video-stats span {
  color: #ff5e62;
}

.video-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
}

.tag {
  padding: 3px 8px;
  background: #f5f5f5;
  border-radius: 12px;
  font-size: 11px;
  color: #666;
  transition: all 0.3s ease;
}

.video-card:hover .tag {
  background: #ff5e62;
  color: #fff;
}

/* 响应式布局 */
@media (max-width: 992px) {
  .video-grid.pc-mode {
    grid-template-columns: repeat(4, 1fr);
  }
  
  .header-left {
    gap: 40px;
  }
  
  .header-tabs {
    gap: 30px;
  }
  
  .header-search {
    width: 180px;
  }
}

@media (max-width: 768px) {
  .video-container {
    padding-top: 60px;
    width: calc(100% - 80px);
    margin-left: 80px;
  }
  
  .video-header {
    height: 60px;
    padding: 0 20px;
    left: 80px;
    width: calc(100% - 80px);
  }
  
  .video-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
  }
  
  .video-grid.pc-mode {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .header-left {
    gap: 20px;
  }
  
  .header-tabs {
    height: 60px;
    gap: 20px;
  }
  
  .tab-item {
    height: 60px;
    line-height: 60px;
  }
  
  .header-search {
    width: auto;
    padding: 0 10px;
  }
  
  .header-search span,
  .upload-btn span {
    display: none;
  }
  
  .upload-btn {
    padding: 0 12px;
  }
}

/* 添加动画效果 */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.video-card:nth-child(1) { animation-delay: 0.1s; }
.video-card:nth-child(2) { animation-delay: 0.2s; }
.video-card:nth-child(3) { animation-delay: 0.3s; }
.video-card:nth-child(4) { animation-delay: 0.4s; }
.video-card:nth-child(5) { animation-delay: 0.5s; }
.video-card:nth-child(6) { animation-delay: 0.6s; }

/* 在大屏幕上适当调整 */
@media (min-width: 1600px) {
  .video-grid.pc-mode {
    grid-template-columns: repeat(6, 1fr);
    margin-left: 0.5%;
  }
}

@media (min-width: 1800px) {
  .video-grid.pc-mode {
    grid-template-columns: repeat(7, 1fr);
  }
}
</style>