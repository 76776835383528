<template>
  <div class="ai-background">
    <!-- 粒子背景 -->
    <div class="particles-container">
      <div v-for="n in 80" :key="n" class="particle" :style="getRandomParticleStyle()"></div>
    </div>

    <!-- 网格线背景 -->
    <div class="grid-container">
      <div class="grid-horizontal" v-for="i in 15" :key="`h-${i}`"></div>
      <div class="grid-vertical" v-for="i in 15" :key="`v-${i}`"></div>
    </div>

    <!-- 圆形光晕效果 -->
    <div class="glow-effects">
      <div class="glow-circle glow-1"></div>
      <div class="glow-circle glow-2"></div>
      <div class="glow-circle glow-3"></div>
    </div>

    <!-- 十六进制代码雨 -->
    <div class="code-rain">
      <div v-for="i in 12" :key="`code-${i}`" class="code-column" :style="{left: `${i * 8}%`}">
        <div class="code-character" v-for="j in 15" :key="`char-${j}`" :style="getCodeCharStyle(j)">
          {{ getRandomHex() }}
        </div>
      </div>
    </div>

    <!-- 中央光圈 -->
    <div class="central-circle">
      <div class="inner-circle"></div>
      <div class="middle-circle"></div>
      <div class="outer-circle"></div>
      <div class="pulse-circle"></div>
    </div>
    
    <!-- 流动线条 -->
    <div class="flow-lines">
      <div v-for="i in 6" :key="`flow-${i}`" class="flow-line" :style="getFlowLineStyle(i)"></div>
    </div>
    
    <!-- 光点闪烁 -->
    <div class="sparkles">
      <div v-for="i in 20" :key="`sparkle-${i}`" class="sparkle" :style="getSparkleStyle()"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AiBackground',
  data() {
    return {
      colors: [
        'rgba(74, 158, 255, 0.8)',  // 蓝色
        'rgba(99, 102, 241, 0.8)',  // 紫蓝色
        'rgba(16, 185, 129, 0.8)',  // 青绿色
        'rgba(59, 130, 246, 0.8)',  // 明亮蓝色
        'rgba(168, 85, 247, 0.8)'   // 紫色
      ]
    }
  },
  methods: {
    getRandomParticleStyle() {
      const size = Math.random() * 6 + 1
      const x = Math.random() * 100
      const y = Math.random() * 100
      const duration = Math.random() * 15 + 20
      const delay = Math.random() * 8
      const opacity = Math.random() * 0.5 + 0.3
      const colorIndex = Math.floor(Math.random() * this.colors.length)

      return {
        width: `${size}px`,
        height: `${size}px`,
        left: `${x}%`,
        top: `${y}%`,
        backgroundColor: this.colors[colorIndex],
        animationDuration: `${duration}s`,
        animationDelay: `${delay}s`,
        opacity: opacity
      }
    },
    getRandomHex() {
      const hex = "0123456789ABCDEF"
      return hex[Math.floor(Math.random() * 16)]
    },
    getCodeCharStyle(index) {
      const delay = Math.random() * 8
      const duration = Math.random() * 5 + 2
      const colorIndex = Math.floor(Math.random() * this.colors.length)
      const color = this.colors[colorIndex].replace('0.8', '0.4')

      return {
        animationDelay: `${delay}s`,
        animationDuration: `${duration}s`,
        top: `${index * 30}px`,
        color: color
      }
    },
    getFlowLineStyle(index) {
      const width = Math.random() * 150 + 100
      const height = Math.random() * 2 + 1
      const x = Math.random() * 80 + 10
      const y = index * 15
      const duration = Math.random() * 8 + 10
      const delay = Math.random() * 5
      const colorIndex = Math.floor(Math.random() * this.colors.length)
      const color = this.colors[colorIndex].replace('0.8', '0.2')

      return {
        width: `${width}px`,
        height: `${height}px`,
        left: `${x}%`,
        top: `${y}%`,
        backgroundColor: color,
        animationDuration: `${duration}s`,
        animationDelay: `${delay}s`
      }
    },
    getSparkleStyle() {
      const size = Math.random() * 3 + 1
      const x = Math.random() * 100
      const y = Math.random() * 100
      const duration = Math.random() * 3 + 1
      const delay = Math.random() * 5
      const colorIndex = Math.floor(Math.random() * this.colors.length)

      return {
        width: `${size}px`,
        height: `${size}px`,
        left: `${x}%`,
        top: `${y}%`,
        backgroundColor: this.colors[colorIndex],
        animationDuration: `${duration}s`,
        animationDelay: `${delay}s`
      }
    }
  }
}
</script>

<style scoped>
.ai-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
  z-index: 1;
  background: linear-gradient(135deg, rgba(2, 3, 23, 0.9) 0%, rgba(7, 12, 34, 0.95) 100%);
}

/* 粒子样式 */
.particles-container {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.particle {
  position: absolute;
  border-radius: 50%;
  animation: float linear infinite;
  box-shadow: 0 0 10px currentColor;
  filter: blur(1px);
}

/* 网格线样式 */
.grid-container {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.grid-horizontal {
  position: absolute;
  width: 100%;
  height: 1px;
  background: linear-gradient(90deg,
  rgba(74, 158, 255, 0) 0%,
  rgba(74, 158, 255, 0.03) 20%,
  rgba(74, 158, 255, 0.08) 50%,
  rgba(74, 158, 255, 0.03) 80%,
  rgba(74, 158, 255, 0) 100%);
  animation: gridPulse 8s infinite alternate;
}

.grid-vertical {
  position: absolute;
  height: 100%;
  width: 1px;
  background: linear-gradient(0deg,
  rgba(74, 158, 255, 0) 0%,
  rgba(74, 158, 255, 0.03) 20%,
  rgba(74, 158, 255, 0.08) 50%,
  rgba(74, 158, 255, 0.03) 80%,
  rgba(74, 158, 255, 0) 100%);
  animation: gridPulse 8s infinite alternate-reverse;
}

/* 计算网格线位置 */
.grid-horizontal:nth-child(1) { top: 6.67%; }
.grid-horizontal:nth-child(2) { top: 13.33%; }
.grid-horizontal:nth-child(3) { top: 20%; }
.grid-horizontal:nth-child(4) { top: 26.67%; }
.grid-horizontal:nth-child(5) { top: 33.33%; }
.grid-horizontal:nth-child(6) { top: 40%; }
.grid-horizontal:nth-child(7) { top: 46.67%; }
.grid-horizontal:nth-child(8) { top: 53.33%; }
.grid-horizontal:nth-child(9) { top: 60%; }
.grid-horizontal:nth-child(10) { top: 66.67%; }
.grid-horizontal:nth-child(11) { top: 73.33%; }
.grid-horizontal:nth-child(12) { top: 80%; }
.grid-horizontal:nth-child(13) { top: 86.67%; }
.grid-horizontal:nth-child(14) { top: 93.33%; }
.grid-horizontal:nth-child(15) { top: 100%; }

.grid-vertical:nth-child(16) { left: 6.67%; }
.grid-vertical:nth-child(17) { left: 13.33%; }
.grid-vertical:nth-child(18) { left: 20%; }
.grid-vertical:nth-child(19) { left: 26.67%; }
.grid-vertical:nth-child(20) { left: 33.33%; }
.grid-vertical:nth-child(21) { left: 40%; }
.grid-vertical:nth-child(22) { left: 46.67%; }
.grid-vertical:nth-child(23) { left: 53.33%; }
.grid-vertical:nth-child(24) { left: 60%; }
.grid-vertical:nth-child(25) { left: 66.67%; }
.grid-vertical:nth-child(26) { left: 73.33%; }
.grid-vertical:nth-child(27) { left: 80%; }
.grid-vertical:nth-child(28) { left: 86.67%; }
.grid-vertical:nth-child(29) { left: 93.33%; }
.grid-vertical:nth-child(30) { left: 100%; }

/* 圆形光晕效果 */
.glow-effects {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.glow-circle {
  position: absolute;
  border-radius: 50%;
  filter: blur(60px);
}

.glow-1 {
  width: 400px;
  height: 400px;
  background-color: rgba(74, 158, 255, 0.08);
  top: -150px;
  right: -150px;
  animation: glow1 25s infinite alternate;
}

.glow-2 {
  width: 300px;
  height: 300px;
  background-color: rgba(99, 102, 241, 0.08);
  bottom: -100px;
  left: -100px;
  animation: glow2 30s infinite alternate;
}

.glow-3 {
  width: 350px;
  height: 350px;
  background-color: rgba(16, 185, 129, 0.06);
  top: 40%;
  left: 60%;
  animation: glow3 35s infinite alternate;
}

/* 十六进制代码雨 */
.code-rain {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.code-column {
  position: absolute;
  top: 0;
  height: 100%;
}

.code-character {
  position: absolute;
  font-family: monospace;
  font-size: 12px;
  animation: codeRain linear infinite;
  text-shadow: 0 0 5px currentColor;
}

/* 流动线条 */
.flow-lines {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.flow-line {
  position: absolute;
  height: 1px;
  border-radius: 4px;
  animation: flowLine 10s linear infinite;
  opacity: 0;
}

/* 光点闪烁 */
.sparkles {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.sparkle {
  position: absolute;
  border-radius: 50%;
  animation: sparkle ease-in-out infinite;
  box-shadow: 0 0 8px currentColor;
}

/* 中央光圈 */
.central-circle {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.inner-circle, .middle-circle, .outer-circle {
  position: absolute;
  border-radius: 50%;
  border: 1px solid rgba(74, 158, 255, 0.3);
  box-shadow: 0 0 15px rgba(74, 158, 255, 0.1);
}

.inner-circle {
  width: 120px;
  height: 120px;
  animation: rotate 20s linear infinite;
  background: radial-gradient(circle, rgba(74, 158, 255, 0.05) 0%, rgba(74, 158, 255, 0) 70%);
}

.middle-circle {
  width: 240px;
  height: 240px;
  animation: rotate 30s linear infinite reverse;
  background: radial-gradient(circle, rgba(99, 102, 241, 0.03) 0%, rgba(99, 102, 241, 0) 70%);
}

.outer-circle {
  width: 360px;
  height: 360px;
  animation: rotate 40s linear infinite;
  background: radial-gradient(circle, rgba(16, 185, 129, 0.02) 0%, rgba(16, 185, 129, 0) 70%);
}

.pulse-circle {
  position: absolute;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: rgba(74, 158, 255, 0.05);
  animation: pulse 4s infinite;
}

.inner-circle::before,
.middle-circle::before,
.outer-circle::before {
  content: '';
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: rgba(74, 158, 255, 0.8);
  border-radius: 50%;
  box-shadow: 0 0 15px rgba(74, 158, 255, 0.8);
}

.inner-circle::after,
.middle-circle::after,
.outer-circle::after {
  content: '';
  position: absolute;
  width: 6px;
  height: 6px;
  background-color: rgba(99, 102, 241, 0.8);
  border-radius: 50%;
  box-shadow: 0 0 15px rgba(99, 102, 241, 0.8);
}

.inner-circle::before {
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.inner-circle::after {
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.middle-circle::before {
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.middle-circle::after {
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.outer-circle::before {
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.outer-circle::after {
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

/* 动画定义 */
@keyframes float {
  0% {
    transform: translateY(0) scale(1);
    opacity: 0;
  }
  10% {
    opacity: 0.8;
  }
  90% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(-500px) scale(0.8);
    opacity: 0;
  }
}

@keyframes glow1 {
  0%, 100% {
    transform: translate(0, 0) scale(1);
  }
  50% {
    transform: translate(-100px, 100px) scale(1.2);
  }
}

@keyframes glow2 {
  0%, 100% {
    transform: translate(0, 0) scale(1);
  }
  50% {
    transform: translate(100px, -100px) scale(1.3);
  }
}

@keyframes glow3 {
  0%, 100% {
    transform: translate(0, 0) scale(1);
  }
  50% {
    transform: translate(-80px, 50px) scale(1.2);
  }
}

@keyframes codeRain {
  0% {
    opacity: 0;
    transform: translateY(-30px);
  }
  10% {
    opacity: 0.8;
  }
  90% {
    opacity: 0.8;
  }
  100% {
    opacity: 0;
    transform: translateY(500px);
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.8);
    opacity: 0.6;
  }
  50% {
    transform: scale(1.2);
    opacity: 0.2;
  }
  100% {
    transform: scale(0.8);
    opacity: 0.6;
  }
}

@keyframes flowLine {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }
  10% {
    opacity: 0.6;
  }
  90% {
    opacity: 0.6;
  }
  100% {
    opacity: 0;
    transform: translateX(calc(100vw + 100px));
  }
}

@keyframes sparkle {
  0%, 100% {
    opacity: 0;
    transform: scale(0.5);
  }
  50% {
    opacity: 1;
    transform: scale(1.2);
  }
}

@keyframes gridPulse {
  0%, 100% {
    opacity: 0.3;
  }
  50% {
    opacity: 0.6;
  }
}
</style> 