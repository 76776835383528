<template>
    <div class="comment-container" :class="{ 'show': isVisible }">
      <div class="comment-header">
        <i class="el-icon-close close-icon" @click="$emit('close')"></i>
        <div class="header-title">评论 {{ commentCount }}</div>
      </div>
      
      <div class="comment-list" ref="commentList">
        <div v-if="comments.length === 0" class="empty-comment">
          <i class="el-icon-chat-dot-square empty-icon"></i>
          <div class="empty-text">暂无评论，快来抢沙发吧~</div>
        </div>
        
        <transition-group name="comment-fade" tag="div">
          <div v-for="comment in comments" :key="comment.id" class="comment-item">
            <div class="comment-avatar">
              <img :src="comment.avatar" :alt="comment.username">
            </div>
            <div class="comment-content">
              <div class="comment-user">{{ comment.username }}</div>
              <div class="comment-text">{{ comment.content }}</div>
              <div class="comment-footer">
                <div class="comment-time">{{ formatTime(comment.time) }}</div>
                <div class="comment-reply" @click="replyTo(comment)">回复</div>
              </div>
              
              <!-- 子评论 -->
              <div class="reply-list" v-if="comment.replies && comment.replies.length > 0">
                <transition-group name="reply-fade" tag="div">
                  <div v-for="reply in comment.replies" :key="reply.id" class="reply-item">
                    <div class="reply-avatar">
                      <img :src="reply.avatar" :alt="reply.username">
                    </div>
                    <div class="reply-content">
                      <div class="reply-user">
                        {{ reply.username }}
                        <span class="reply-to" v-if="reply.replyTo">回复 {{ reply.replyTo }}</span>
                      </div>
                      <div class="reply-text">{{ reply.content }}</div>
                      <div class="reply-footer">
                        <div class="reply-time">{{ formatTime(reply.time) }}</div>
                        <div class="reply-button" @click="replyTo(reply, comment.username)">回复</div>
                      </div>
                    </div>
                    <div class="reply-like" @click="toggleLike(reply)">
                      <i :class="reply.isLiked ? 'el-icon-star-on' : 'el-icon-star-off'"></i>
                      <span v-if="reply.likes > 0">{{ reply.likes }}</span>
                    </div>
                  </div>
                </transition-group>
              </div>
            </div>
            <div class="comment-like" @click="toggleLike(comment)">
              <i :class="comment.isLiked ? 'el-icon-star-on' : 'el-icon-star-off'"></i>
              <span v-if="comment.likes > 0">{{ comment.likes }}</span>
            </div>
          </div>
        </transition-group>
        
        <div class="load-more" v-if="hasMoreComments" @click="loadMoreComments">
          <i class="el-icon-refresh"></i> 加载更多
        </div>
      </div>
      
      <div class="comment-input">
        <div v-if="replyingTo" class="replying-tip">
          回复 <span class="replying-name">{{ replyingTo }}</span>
          <i class="el-icon-close cancel-reply" @click="cancelReply"></i>
        </div>
        <div class="input-wrapper">
          <el-input
            v-model="commentText"
            type="text"
            placeholder="友善评论，文明发言"
            @keyup.enter.native="submitComment"
          >
            <el-button
              slot="append"
              :disabled="!commentText.trim()"
              @click="submitComment"
            >发送</el-button>
          </el-input>
        </div>
        <div class="input-tools">
          <i class="el-icon-picture-outline tool-icon"></i>
          <i class="el-icon-magic-stick tool-icon"></i>
          <i class="el-icon-present tool-icon"></i>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'VideoComment',
    props: {
      video: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        comments: [],
        commentText: '',
        page: 1,
        pageSize: 10,
        hasMoreComments: false,
        replyingTo: '',
        replyingId: null,
        isReplyToComment: true,
        isVisible: false
      }
    },
    computed: {
      commentCount() {
        return this.video.comments || 0
      }
    },
    created() {
      this.fetchComments()
      // 添加显示动画
      this.$nextTick(() => {
        this.isVisible = true
      })
    },
    methods: {
      fetchComments() {
        // 模拟从API获取评论数据
        setTimeout(() => {
          // 模拟评论数据
          const mockComments = [
            {
              id: 1,
              username: '用户一',
              avatar: 'https://example.com/avatar1.jpg',
              content: '这个视频太精彩了！',
              time: Date.now() - 1000 * 60 * 5, // 5分钟前
              likes: 12,
              isLiked: false,
              replies: [
                {
                  id: 101,
                  username: '用户二',
                  avatar: 'https://example.com/avatar2.jpg',
                  content: '我也觉得很棒！',
                  time: Date.now() - 1000 * 60 * 3, // 3分钟前
                  likes: 5,
                  isLiked: true,
                  replyTo: '用户一'
                }
              ]
            },
            {
              id: 2,
              username: '用户三',
              avatar: 'https://example.com/avatar3.jpg',
              content: '希望能看到更多这样的内容！',
              time: Date.now() - 1000 * 60 * 30, // 30分钟前
              likes: 8,
              isLiked: false,
              replies: []
            }
          ]
          
          this.comments = [...this.comments, ...mockComments]
          this.hasMoreComments = this.page < 3 // 模拟只有3页数据
          this.page++
        }, 500)
      },
      loadMoreComments() {
        this.fetchComments()
      },
      formatTime(timestamp) {
        const now = Date.now()
        const diff = now - timestamp
        
        // 1分钟内
        if (diff < 60 * 1000) {
          return '刚刚'
        }
        // 1小时内
        if (diff < 60 * 60 * 1000) {
          return `${Math.floor(diff / (60 * 1000))}分钟前`
        }
        // 24小时内
        if (diff < 24 * 60 * 60 * 1000) {
          return `${Math.floor(diff / (60 * 60 * 1000))}小时前`
        }
        // 30天内
        if (diff < 30 * 24 * 60 * 60 * 1000) {
          return `${Math.floor(diff / (24 * 60 * 60 * 1000))}天前`
        }
        
        // 超过30天，显示具体日期
        const date = new Date(timestamp)
        return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
      },
      toggleLike(comment) {
        comment.isLiked = !comment.isLiked
        comment.likes += comment.isLiked ? 1 : -1
        if (comment.likes < 0) comment.likes = 0
      },
      replyTo(comment, parentName = null) {
        this.replyingId = comment.id
        this.replyingTo = parentName || comment.username
        this.isReplyToComment = !parentName
        
        // 聚焦输入框
        this.$nextTick(() => {
          const input = this.$el.querySelector('input')
          if (input) input.focus()
        })
      },
      cancelReply() {
        this.replyingTo = ''
        this.replyingId = null
      },
      submitComment() {
        if (!this.commentText.trim()) return
        
        // 模拟添加评论/回复
        if (this.replyingTo) {
          // 添加回复
          if (this.isReplyToComment) {
            // 回复主评论
            const comment = this.comments.find(c => c.id === this.replyingId)
            if (comment) {
              if (!comment.replies) comment.replies = []
              
              comment.replies.push({
                id: Date.now(),
                username: '当前用户', // 实际应用中应该使用登录用户的信息
                avatar: 'https://example.com/current-user-avatar.jpg',
                content: this.commentText,
                time: Date.now(),
                likes: 0,
                isLiked: false,
                replyTo: this.replyingTo
              })
            }
          } else {
            // 回复子评论
            this.comments.forEach(comment => {
              if (comment.replies) {
                const replyIndex = comment.replies.findIndex(r => r.id === this.replyingId)
                if (replyIndex !== -1) {
                  comment.replies.push({
                    id: Date.now(),
                    username: '当前用户',
                    avatar: 'https://example.com/current-user-avatar.jpg',
                    content: this.commentText,
                    time: Date.now(),
                    likes: 0,
                    isLiked: false,
                    replyTo: this.replyingTo
                  })
                }
              }
            })
          }
        } else {
          // 添加新评论
          this.comments.unshift({
            id: Date.now(),
            username: '当前用户',
            avatar: 'https://example.com/current-user-avatar.jpg',
            content: this.commentText,
            time: Date.now(),
            likes: 0,
            isLiked: false,
            replies: []
          })
        }
        
        // 更新评论数量
        if (this.video) {
          this.video.comments = (this.video.comments || 0) + 1
        }
        
        // 重置输入框
        this.commentText = ''
        this.replyingTo = ''
        this.replyingId = null
      }
    }
  }
  </script>
  
  <style scoped>
  .comment-container {
    position: fixed;
    right: 0;
    top: 0;
    width: 400px;
    height: 100vh;
    background-color: #fff;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    box-shadow: -2px 0 10px rgba(0, 0, 0, 0.1);
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
  }
  
  .comment-container.show {
    transform: translateX(0);
  }
  
  .comment-header {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 60px;
    padding: 0 20px;
    border-bottom: 1px solid #eee;
    background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  }
  
  .close-icon {
    position: absolute;
    left: 20px;
    font-size: 24px;
    cursor: pointer;
    transition: transform 0.2s ease;
  }
  
  .close-icon:hover {
    transform: rotate(90deg);
  }
  
  .header-title {
    font-size: 18px;
    font-weight: bold;
    color: #2c3e50;
  }
  
  .comment-list {
    flex: 1;
    overflow-y: auto;
    padding: 20px;
    background-color: #f8f9fa;
  }
  
  .empty-comment {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #999;
  }
  
  .empty-icon {
    font-size: 60px;
    margin-bottom: 20px;
    color: #c3cfe2;
  }
  
  .comment-item {
    display: flex;
    margin-bottom: 25px;
    padding: 15px;
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.05);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
  }
  
  .comment-item:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  }
  
  .comment-avatar {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 15px;
    flex-shrink: 0;
    border: 2px solid #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .comment-avatar img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
  }
  
  .comment-avatar:hover img {
    transform: scale(1.1);
  }
  
  .comment-content {
    flex: 1;
    min-width: 0;
  }
  
  .comment-user {
    font-weight: bold;
    margin-bottom: 8px;
    color: #2c3e50;
  }
  
  .comment-text {
    margin-bottom: 8px;
    word-break: break-word;
    line-height: 1.6;
    color: #34495e;
  }
  
  .comment-footer {
    display: flex;
    align-items: center;
    color: #7f8c8d;
    font-size: 13px;
    margin-bottom: 10px;
  }
  
  .comment-time {
    margin-right: 20px;
  }
  
  .comment-reply {
    cursor: pointer;
    transition: color 0.2s ease;
  }
  
  .comment-reply:hover {
    color: #3498db;
  }
  
  .comment-like {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 15px;
    color: #7f8c8d;
    cursor: pointer;
    transition: transform 0.2s ease;
  }
  
  .comment-like:hover {
    transform: scale(1.1);
  }
  
  .comment-like i {
    font-size: 20px;
  }
  
  .comment-like .el-icon-star-on {
    color: #e74c3c;
  }
  
  .reply-list {
    background-color: #f8f9fa;
    border-radius: 8px;
    padding: 15px;
    margin-top: 10px;
  }
  
  .reply-item {
    display: flex;
    margin-bottom: 15px;
    padding: 10px;
    background: #fff;
    border-radius: 8px;
    transition: transform 0.2s ease;
  }
  
  .reply-item:hover {
    transform: translateX(5px);
  }
  
  .reply-avatar {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 12px;
    flex-shrink: 0;
    border: 2px solid #fff;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  }
  
  .reply-avatar img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .reply-content {
    flex: 1;
    min-width: 0;
  }
  
  .reply-user {
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 5px;
    color: #2c3e50;
  }
  
  .reply-to {
    color: #7f8c8d;
    font-weight: normal;
    margin-left: 5px;
  }
  
  .reply-text {
    font-size: 14px;
    margin-bottom: 5px;
    word-break: break-word;
    color: #34495e;
  }
  
  .reply-footer {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #7f8c8d;
  }
  
  .reply-time {
    margin-right: 20px;
  }
  
  .reply-button {
    cursor: pointer;
    transition: color 0.2s ease;
  }
  
  .reply-button:hover {
    color: #3498db;
  }
  
  .reply-like {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 12px;
    font-size: 14px;
    color: #7f8c8d;
    cursor: pointer;
    transition: transform 0.2s ease;
  }
  
  .reply-like:hover {
    transform: scale(1.1);
  }
  
  .reply-like .el-icon-star-on {
    color: #e74c3c;
  }
  
  .load-more {
    text-align: center;
    color: #7f8c8d;
    padding: 15px 0;
    cursor: pointer;
    transition: color 0.2s ease;
  }
  
  .load-more:hover {
    color: #3498db;
  }
  
  .comment-input {
    padding: 20px;
    border-top: 1px solid #eee;
    background-color: #fff;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.05);
  }
  
  .replying-tip {
    font-size: 13px;
    color: #666;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    padding: 8px 12px;
    background: #f8f9fa;
    border-radius: 6px;
  }
  
  .replying-name {
    color: #e74c3c;
    margin: 0 5px;
    font-weight: bold;
  }
  
  .cancel-reply {
    margin-left: 10px;
    cursor: pointer;
    transition: transform 0.2s ease;
  }
  
  .cancel-reply:hover {
    transform: rotate(90deg);
  }
  
  .input-wrapper {
    margin-bottom: 15px;
  }
  
  .input-tools {
    display: flex;
    align-items: center;
    padding: 10px 0;
  }
  
  .tool-icon {
    font-size: 22px;
    color: #7f8c8d;
    margin-right: 20px;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .tool-icon:hover {
    color: #3498db;
    transform: scale(1.1);
  }
  
  /* 动画效果 */
  .comment-fade-enter-active,
  .comment-fade-leave-active,
  .reply-fade-enter-active,
  .reply-fade-leave-active {
    transition: all 0.3s ease;
  }
  
  .comment-fade-enter,
  .comment-fade-leave-to,
  .reply-fade-enter,
  .reply-fade-leave-to {
    opacity: 0;
    transform: translateY(20px);
  }
  
  /* 自定义滚动条样式 */
  .comment-list::-webkit-scrollbar {
    width: 6px;
  }
  
  .comment-list::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 3px;
  }
  
  .comment-list::-webkit-scrollbar-thumb {
    background: #c3cfe2;
    border-radius: 3px;
  }
  
  .comment-list::-webkit-scrollbar-thumb:hover {
    background: #a8b4c5;
  }
  </style>