<template>
  <div class="video-fullscreen" v-if="visible">
    <div class="fullscreen-container">
      <!-- 关闭按钮 -->
      <div class="close-button" @click="closePlayer">
        <i class="el-icon-close"></i>
      </div>
      
      <!-- 主要内容区域 -->
      <div class="content-container">
        <!-- 左侧视频播放区 -->
        <div class="video-container">
          <video
            ref="videoElement"
            :src="video.url"
            :poster="video.cover"
            :loop="true"
            :autoplay="true"
            :muted="isMuted"
            class="video-player"
            @click="togglePlay"
          ></video>
          
          <div class="play-icon" v-if="!isPlaying">
            <i class="el-icon-video-play"></i>
          </div>
          
          <!-- 视频信息 -->
          <div class="video-info">
            <h3 class="video-title">{{ video.title }}</h3>
            <p class="video-desc">{{ video.description }}</p>
          </div>
          
          <!-- 底部控制栏 -->
          <div class="video-controls">
            <div class="progress-bar" @click="seekVideo">
              <div class="progress" :style="{ width: progress + '%' }"></div>
            </div>
            <div class="control-buttons">
              <div class="control-button" @click.stop="togglePlay">
                <i :class="isPlaying ? 'el-icon-video-pause' : 'el-icon-video-play'"></i>
              </div>
              <div class="control-button" @click.stop="toggleMute">
                <i :class="isMuted ? 'el-icon-turn-off-microphone' : 'el-icon-microphone'"></i>
              </div>
              <div class="time-display">
                {{ currentTime }} / {{ duration }}
              </div>
            </div>
          </div>
        </div>
        
        <!-- 右侧互动区域 -->
        <div class="interaction-container">
          <!-- 作者信息 -->
          <div class="author-info">
            <div class="author-avatar">
              <img :src="video.author.avatar" :alt="video.author.name">
            </div>
            <div class="author-name">{{ video.author.name }}</div>
            <div 
              class="follow-btn"
              :class="{ 'followed': video.author.isFollowed }"
              @click="toggleFollow"
            >
              {{ video.author.isFollowed ? '已关注' : '+ 关注' }}
            </div>
          </div>
          
          <!-- 互动按钮 -->
          <div class="interaction-buttons">
            <div class="interaction-button" @click="handleLike">
              <div class="interaction-icon" :class="{ 'active': video.isLiked }">
                <i :class="video.isLiked ? 'el-icon-star-on' : 'el-icon-star-off'"></i>
              </div>
              <div class="interaction-count">{{ formatNumber(video.likes) }}</div>
              <div class="interaction-text">点赞</div>
            </div>
            
            <div class="interaction-button" @click="handleComment">
              <div class="interaction-icon">
                <i class="el-icon-chat-dot-square"></i>
              </div>
              <div class="interaction-count">{{ formatNumber(video.comments) }}</div>
              <div class="interaction-text">评论</div>
            </div>
            
            <div class="interaction-button" @click="handleShare">
              <div class="interaction-icon">
                <i class="el-icon-share"></i>
              </div>
              <div class="interaction-count">{{ formatNumber(video.shares) }}</div>
              <div class="interaction-text">分享</div>
            </div>
          </div>
          
          <!-- 视频标签 -->
          <div class="video-tags">
            <span v-for="tag in video.tags" :key="tag" class="tag">
              # {{ tag }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VideoFullScreen',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    video: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      isPlaying: true,
      isMuted: false,
      progress: 0,
      currentTime: '0:00',
      duration: '0:00'
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.$nextTick(() => {
          this.playVideo()
        })
      } else {
        this.pauseVideo()
      }
    }
  },
  mounted() {
    if (this.$refs.videoElement) {
      this.$refs.videoElement.addEventListener('timeupdate', this.updateProgress)
      this.$refs.videoElement.addEventListener('loadedmetadata', this.updateDuration)
      this.$refs.videoElement.addEventListener('ended', this.videoEnded)
    }
  },
  beforeDestroy() {
    if (this.$refs.videoElement) {
      this.$refs.videoElement.removeEventListener('timeupdate', this.updateProgress)
      this.$refs.videoElement.removeEventListener('loadedmetadata', this.updateDuration)
      this.$refs.videoElement.removeEventListener('ended', this.videoEnded)
    }
  },
  methods: {
    updateProgress() {
      const video = this.$refs.videoElement
      if (video) {
        this.progress = (video.currentTime / video.duration) * 100
        this.currentTime = this.formatTime(video.currentTime)
      }
    },
    updateDuration() {
      const video = this.$refs.videoElement
      if (video) {
        this.duration = this.formatTime(video.duration)
      }
    },
    formatTime(seconds) {
      const minutes = Math.floor(seconds / 60)
      seconds = Math.floor(seconds % 60)
      return `${minutes}:${seconds.toString().padStart(2, '0')}`
    },
    togglePlay() {
      if (this.isPlaying) {
        this.pauseVideo()
      } else {
        this.playVideo()
      }
    },
    playVideo() {
      if (this.$refs.videoElement) {
        this.$refs.videoElement.play()
          .then(() => {
            this.isPlaying = true
          })
          .catch(err => {
            console.error('视频播放失败:', err)
            this.isPlaying = false
          })
      }
    },
    pauseVideo() {
      if (this.$refs.videoElement) {
        this.$refs.videoElement.pause()
        this.isPlaying = false
      }
    },
    videoEnded() {
      // 视频播放结束，可以循环播放
      this.playVideo()
    },
    toggleMute() {
      this.isMuted = !this.isMuted
    },
    seekVideo(event) {
      if (this.$refs.videoElement) {
        const progressBar = event.currentTarget
        const clickPosition = (event.clientX - progressBar.getBoundingClientRect().left) / progressBar.offsetWidth
        this.$refs.videoElement.currentTime = this.$refs.videoElement.duration * clickPosition
      }
    },
    closePlayer() {
      this.$emit('close')
    },
    handleLike() {
      this.$emit('like', this.video.id)
      // 本地更新点赞状态，实际应用中应该通过API请求后再更新
      this.video.isLiked = !this.video.isLiked
      this.video.likes += this.video.isLiked ? 1 : -1
    },
    handleComment() {
      this.$emit('comment', this.video.id)
    },
    handleShare() {
      this.$emit('share', this.video.id)
    },
    toggleFollow() {
      this.$emit('follow', this.video.author.id)
      // 本地更新关注状态，实际应用中应该通过API请求后再更新
      this.video.author.isFollowed = !this.video.author.isFollowed
    },
    formatNumber(num) {
      if (num >= 10000) {
        return (num / 10000).toFixed(1) + 'w'
      } else if (num >= 1000) {
        return (num / 1000).toFixed(1) + 'k'
      }
      return num
    }
  }
}
</script>

<style scoped>
.video-fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 0.3s ease;
}

.fullscreen-container {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 1600px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.close-button {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  z-index: 10;
  transition: all 0.3s ease;
}

.close-button:hover {
  background-color: rgba(255, 94, 98, 0.8);
  transform: rotate(90deg);
}

.content-container {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
}

.video-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.video-player {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.play-icon {
  position: absolute;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 30px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.play-icon:hover {
  background-color: rgba(255, 94, 98, 0.8);
  transform: scale(1.1);
}

.video-info {
  position: absolute;
  left: 20px;
  bottom: 80px;
  color: #fff;
  max-width: 70%;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  animation: slideIn 0.5s ease;
}

.video-title {
  font-size: 20px;
  margin-bottom: 10px;
  line-height: 1.4;
}

.video-desc {
  font-size: 14px;
  opacity: 0.9;
  margin-bottom: 10px;
  line-height: 1.6;
}

.video-controls {
  position: absolute;
  bottom: 20px;
  left: 0;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
}

.progress-bar {
  width: 100%;
  height: 4px;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 2px;
  cursor: pointer;
  margin-bottom: 10px;
  position: relative;
}

.progress {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #ff5e62;
  border-radius: 2px;
  transition: width 0.1s linear;
}

.control-buttons {
  display: flex;
  align-items: center;
}

.control-button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.2);
  margin-right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  cursor: pointer;
  transition: all 0.3s ease;
}

.control-button:hover {
  background-color: rgba(255, 94, 98, 0.8);
}

.time-display {
  color: #fff;
  font-size: 14px;
}

.interaction-container {
  position: absolute;
  width: auto;
  height: auto;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 15px;
}

.author-info {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
  animation: slideRight 0.5s ease;
}

.author-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 15px;
  border: 2px solid #fff;
}

.author-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.author-name {
  font-size: 18px;
  color: #fff;
  font-weight: bold;
  margin-right: 20px;
}

.follow-btn {
  padding: 5px 15px;
  background: linear-gradient(135deg, #ff5e62, #ff9966);
  color: #fff;
  border-radius: 20px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.follow-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(255, 94, 98, 0.3);
}

.follow-btn.followed {
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid #fff;
}

.interaction-buttons {
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-bottom: 50px;
  animation: slideRight 0.7s ease;
}

.interaction-button {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.interaction-icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 24px;
  margin-right: 15px;
  transition: all 0.3s ease;
}

.interaction-icon.active {
  background-color: #ff5e62;
}

.interaction-button:hover .interaction-icon {
  background-color: #ff5e62;
  transform: scale(1.1);
}

.interaction-count {
  font-size: 16px;
  color: #fff;
  margin-right: 10px;
  transition: color 0.3s ease;
}

.interaction-text {
  font-size: 14px;
  color: #ccc;
  transition: color 0.3s ease;
}

.interaction-button:hover .interaction-count,
.interaction-button:hover .interaction-text {
  color: #ff5e62;
}

.video-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  animation: slideRight 0.9s ease;
}

.tag {
  padding: 5px 15px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.tag:hover {
  background-color: rgba(255, 94, 98, 0.8);
  transform: translateY(-2px);
}

/* 动画效果 */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideIn {
  from { transform: translateY(20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

@keyframes slideRight {
  from { transform: translateX(20px); opacity: 0; }
  to { transform: translateX(0); opacity: 1; }
}

@keyframes slideUp {
  from { transform: translateY(50px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

/* 响应式样式 */
@media (max-width: 992px) {
  .content-container {
    flex-direction: column;
  }
  
  .video-container {
    width: 100%;
    height: 100vh;
  }
  
  .interaction-container {
    right: 10px;
    top: auto;
    bottom: 120px;
    transform: none;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .author-info {
    position: absolute;
    left: 15px;
    bottom: 120px;
    margin-bottom: 0;
  }
  
  .author-avatar {
    width: 40px;
    height: 40px;
  }
  
  .author-name {
    font-size: 16px;
  }
  
  .follow-btn {
    padding: 3px 10px;
    font-size: 12px;
  }
  
  .interaction-buttons {
    flex-direction: column;
    gap: 15px;
  }
  
  .interaction-icon {
    width: 40px;
    height: 40px;
    font-size: 20px;
    margin-right: 10px;
  }
  
  .interaction-text {
    display: none;
  }
  
  .video-tags {
    display: none;
  }
  
  .video-info {
    bottom: 60px;
    max-width: 80%;
  }
}
</style> 