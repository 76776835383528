<template>
  <div class="ai-typing-container" v-if="visible">
    <div class="ai-thinking-effect">
      <div class="ai-avatar-container">
        <div class="ai-avatar">
          <div class="ai-avatar-inner"></div>
          <div class="avatar-ring"></div>
        </div>
      </div>
      <div class="typing-text-container">
        <span class="typing-text">AI正在思考</span>
        <div class="dot-container">
          <span class="dot dot1"></span>
          <span class="dot dot2"></span>
          <span class="dot dot3"></span>
        </div>
      </div>
      <div class="thinking-particles">
        <div v-for="n in 8" :key="n" class="particle" :class="`particle-${n}`"></div>
      </div>
      <div class="ai-brain-wave">
        <svg width="100%" height="30" viewBox="0 0 200 30">
          <path d="M0,15 Q25,5 50,15 T100,15 T150,15 T200,15" fill="none" stroke="rgba(64, 158, 255, 0.6)" stroke-width="2">
            <animate attributeName="d"
                    dur="2s"
                    repeatCount="indefinite"
                    values="M0,15 Q25,5 50,15 T100,15 T150,15 T200,15;
                           M0,15 Q25,25 50,15 T100,15 T150,15 T200,15;
                           M0,15 Q25,5 50,15 T100,15 T150,15 T200,15" />
          </path>
        </svg>
      </div>
      <div class="circuit-effect">
        <div class="circuit-line line1"></div>
        <div class="circuit-line line2"></div>
        <div class="circuit-line line3"></div>
        <div class="circuit-dot dot1"></div>
        <div class="circuit-dot dot2"></div>
        <div class="circuit-dot dot3"></div>
      </div>
      <div class="glow-effect"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AITyping',
  props: {
    visible: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {

    }
  }
}
</script>

<style scoped>
.ai-typing-container {
  width: 100%;
  padding: 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  margin-bottom: 15px;
}

.ai-thinking-effect {
  display: flex;
  align-items: center;
  position: relative;
  background: rgba(20, 30, 90, 0.08);
  border-radius: 20px;
  padding: 15px 25px;
  box-shadow: 0 8px 32px rgba(0, 100, 255, 0.15);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(64, 158, 255, 0.18);
  overflow: hidden;
  transition: all 0.3s ease;
}

.ai-thinking-effect:hover {
  box-shadow: 0 8px 32px rgba(0, 100, 255, 0.25);
  transform: translateY(-2px);
}

.ai-avatar-container {
  margin-right: 20px;
  position: relative;
}

.ai-avatar {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background: linear-gradient(135deg, #42b0ff, #4080ff);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  box-shadow: 0 4px 15px rgba(0, 100, 255, 0.4);
  animation: pulse 2s infinite;
  z-index: 2;
}

.avatar-ring {
  position: absolute;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  border-radius: 50%;
  border: 2px solid rgba(64, 158, 255, 0.5);
  animation: rotate 4s linear infinite;
}

.avatar-ring:before {
  content: '';
  position: absolute;
  top: -2px;
  left: 40%;
  width: 10px;
  height: 10px;
  background: #4080ff;
  border-radius: 50%;
  box-shadow: 0 0 10px #4080ff, 0 0 20px #4080ff;
}

.ai-avatar-inner {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.95);
  position: relative;
  overflow: hidden;
  box-shadow: inset 0 0 10px rgba(0, 100, 255, 0.2);
}

.ai-avatar-inner:before {
  content: '';
  position: absolute;
  width: 150%;
  height: 150%;
  top: -25%;
  left: -25%;
  background: linear-gradient(135deg, transparent 20%, rgba(64, 158, 255, 0.8) 50%, transparent 80%);
  animation: rotateGradient 2s linear infinite;
}

.typing-text-container {
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.1);
  padding: 6px 12px;
  border-radius: 30px;
  box-shadow: 0 2px 10px rgba(0, 100, 255, 0.05);
}

.typing-text {
  font-size: 15px;
  font-weight: 500;
  color: #2c3e50;
  margin-right: 6px;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  animation: textPulse 2s infinite;
}

.dot-container {
  display: flex;
  align-items: center;
}

.dot {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #409EFF;
  margin: 0 3px;
  opacity: 0.8;
  box-shadow: 0 0 5px rgba(64, 158, 255, 0.5);
}

.dot1 {
  animation: bounceShadow 1.2s infinite 0s;
}

.dot2 {
  animation: bounceShadow 1.2s infinite 0.2s;
}

.dot3 {
  animation: bounceShadow 1.2s infinite 0.4s;
}

.thinking-particles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  overflow: hidden;
}

.particle {
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: rgba(64, 158, 255, 0.8);
  filter: blur(1px);
  opacity: 0;
  box-shadow: 0 0 8px rgba(64, 158, 255, 0.8);
}

.particle-1 {
  top: 20%;
  left: 30%;
  animation: floatParticle 3s ease-in-out infinite 0.2s;
}

.particle-2 {
  top: 70%;
  left: 40%;
  animation: floatParticle 3.5s ease-in-out infinite 0.5s;
}

.particle-3 {
  top: 40%;
  left: 60%;
  animation: floatParticle 3.2s ease-in-out infinite 0.7s;
}

.particle-4 {
  top: 30%;
  left: 70%;
  animation: floatParticle 3.7s ease-in-out infinite 1s;
}

.particle-5 {
  top: 60%;
  left: 85%;
  animation: floatParticle 3s ease-in-out infinite 1.2s;
}

.particle-6 {
  top: 25%;
  left: 55%;
  animation: floatParticle 4s ease-in-out infinite 0.3s;
}

.particle-7 {
  top: 65%;
  left: 25%;
  animation: floatParticle 3.4s ease-in-out infinite 0.9s;
}

.particle-8 {
  top: 50%;
  left: 90%;
  animation: floatParticle 3.8s ease-in-out infinite 1.5s;
}

.ai-brain-wave {
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  height: 30px;
  opacity: 0.7;
}

.circuit-effect {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  opacity: 0.2;
}

.circuit-line {
  position: absolute;
  background: linear-gradient(90deg, transparent, rgba(64, 158, 255, 0.8), transparent);
  height: 1px;
}

.circuit-line.line1 {
  top: 30%;
  left: 0;
  width: 100%;
  animation: circuitPulse 3s infinite 0.5s;
}

.circuit-line.line2 {
  top: 60%;
  left: 0;
  width: 80%;
  animation: circuitPulse 4s infinite 1s;
}

.circuit-line.line3 {
  top: 45%;
  right: 0;
  width: 60%;
  animation: circuitPulse 3.5s infinite 0.2s;
}

.circuit-dot {
  position: absolute;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: rgba(64, 158, 255, 1);
  box-shadow: 0 0 8px rgba(64, 158, 255, 1);
}

.circuit-dot.dot1 {
  top: 30%;
  left: 40%;
  animation: dotPulse 2s infinite 0.3s;
}

.circuit-dot.dot2 {
  top: 60%;
  left: 65%;
  animation: dotPulse 2s infinite 0.8s;
}

.circuit-dot.dot3 {
  top: 45%;
  left: 25%;
  animation: dotPulse 2s infinite 1.2s;
}

.glow-effect {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle at center, rgba(64, 158, 255, 0.1) 0%, transparent 70%);
  filter: blur(20px);
  opacity: 0.8;
  animation: glowPulse 4s infinite;
  pointer-events: none;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(64, 158, 255, 0.6);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(64, 158, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(64, 158, 255, 0);
  }
}

@keyframes bounceShadow {
  0%, 80%, 100% {
    transform: translateY(0);
    box-shadow: 0 0 5px rgba(64, 158, 255, 0.5);
  }
  40% {
    transform: translateY(-6px);
    box-shadow: 0 10px 10px rgba(64, 158, 255, 0.2);
  }
}

@keyframes floatParticle {
  0% {
    transform: translate(0, 0) scale(0.8);
    opacity: 0;
  }
  25% {
    opacity: 0.8;
  }
  50% {
    transform: translate(-20px, -20px) scale(1.5);
    opacity: 0.3;
  }
  75% {
    opacity: 0.8;
  }
  100% {
    transform: translate(0, 0) scale(0.8);
    opacity: 0;
  }
}

@keyframes rotateGradient {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes textPulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes circuitPulse {
  0%, 100% {
    opacity: 0.1;
  }
  50% {
    opacity: 0.8;
  }
}

@keyframes dotPulse {
  0%, 100% {
    transform: scale(1);
    opacity: 0.5;
  }
  50% {
    transform: scale(1.5);
    opacity: 1;
  }
}

@keyframes glowPulse {
  0%, 100% {
    opacity: 0.5;
    filter: blur(20px);
  }
  50% {
    opacity: 0.8;
    filter: blur(25px);
  }
}
</style>
