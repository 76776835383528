<template>
  <div class="ai-chat-page">
    <ai-chat></ai-chat>
  </div>
</template>

<script>
import AiChat from '@/components/ai/AIChat.vue'

export default {
  name: 'AiChatPage',
  components: {
    AiChat
  }
}
</script>

<style scoped>
.ai-chat-page {
  height: 100%;
  padding: 20px;
  background-color: #0c1223;
}
</style>
