<template>
    <div class="video-player" :class="{ 'pc-mode': isPcMode }">
      <!-- 视频播放区 -->
      <div class="video-wrapper">
        <video
          class="video-element"
          :src="video.url"
          :poster="video.cover"
          :loop="true"
          :muted="isMuted"
          :autoplay="isActive"
          ref="videoEl"
          @click="togglePlay"
        ></video>
        <div class="play-icon" v-if="!isPlaying">
          <i class="el-icon-video-play"></i>
        </div>
        <div class="video-controls" v-if="isPcMode">
          <div class="progress-bar">
            <div class="progress" :style="{ width: progress + '%' }"></div>
          </div>
          <div class="time-display">{{ currentTime }} / {{ duration }}</div>
        </div>
      </div>
      
      <!-- 视频信息 -->
      <div class="video-info" :class="{ 'pc-mode': isPcMode }">
        <h3 class="video-title">{{ video.title }}</h3>
        <p class="video-description">{{ video.description }}</p>
        
        <!-- 作者信息 -->
        <div class="author-info">
          <div class="author-avatar">
            <img :src="video.author.avatar" :alt="video.author.name">
          </div>
          <div class="author-name">{{ video.author.name }}</div>
          <div 
            class="follow-btn"
            :class="{ followed: video.author.isFollowed }"
            @click="toggleFollow"
          >
            {{ video.author.isFollowed ? '已关注' : '+关注' }}
          </div>
        </div>
      </div>
      
      <!-- 互动按钮区域 -->
      <div class="interaction-btns" :class="{ 'pc-mode': isPcMode }">
        <div class="interaction-item" @click="handleLike">
          <div class="interaction-icon" :class="{ active: video.isLiked }">
            <i :class="video.isLiked ? 'el-icon-star-on' : 'el-icon-star-off'"></i>
          </div>
          <div class="interaction-count">{{ formatNumber(video.likes) }}</div>
        </div>
        
        <div class="interaction-item" @click="handleComment">
          <div class="interaction-icon">
            <i class="el-icon-chat-dot-square"></i>
          </div>
          <div class="interaction-count">{{ formatNumber(video.comments) }}</div>
        </div>
        
        <div class="interaction-item" @click="handleShare">
          <div class="interaction-icon">
            <i class="el-icon-share"></i>
          </div>
          <div class="interaction-count">{{ formatNumber(video.shares) }}</div>
        </div>
        
        <div class="interaction-item" @click="toggleMute">
          <div class="interaction-icon">
            <i :class="isMuted ? 'el-icon-turn-off-microphone' : 'el-icon-microphone'"></i>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'VideoPlayer',
    props: {
      video: {
        type: Object,
        required: true
      },
      isActive: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        isPlaying: false,
        isMuted: false,
        isPcMode: false,
        progress: 0,
        currentTime: '0:00',
        duration: '0:00'
      }
    },
    watch: {
      isActive(val) {
        if (val) {
          this.playVideo()
        } else {
          this.pauseVideo()
        }
      }
    },
    mounted() {
      this.checkDeviceMode()
      window.addEventListener('resize', this.checkDeviceMode)
      if (this.$refs.videoEl) {
        this.$refs.videoEl.addEventListener('timeupdate', this.updateProgress)
        this.$refs.videoEl.addEventListener('loadedmetadata', this.updateDuration)
      }
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.checkDeviceMode)
      if (this.$refs.videoEl) {
        this.$refs.videoEl.removeEventListener('timeupdate', this.updateProgress)
        this.$refs.videoEl.removeEventListener('loadedmetadata', this.updateDuration)
      }
    },
    methods: {
      checkDeviceMode() {
        this.isPcMode = window.innerWidth > 768
      },
      updateProgress() {
        const video = this.$refs.videoEl
        if (video) {
          this.progress = (video.currentTime / video.duration) * 100
          this.currentTime = this.formatTime(video.currentTime)
        }
      },
      updateDuration() {
        const video = this.$refs.videoEl
        if (video) {
          this.duration = this.formatTime(video.duration)
        }
      },
      formatTime(seconds) {
        const minutes = Math.floor(seconds / 60)
        seconds = Math.floor(seconds % 60)
        return `${minutes}:${seconds.toString().padStart(2, '0')}`
      },
      togglePlay() {
        if (this.isPlaying) {
          this.pauseVideo()
        } else {
          this.playVideo()
        }
      },
      playVideo() {
        if (this.$refs.videoEl) {
          this.$refs.videoEl.play()
          this.isPlaying = true
        }
      },
      pauseVideo() {
        if (this.$refs.videoEl) {
          this.$refs.videoEl.pause()
          this.isPlaying = false
        }
      },
      toggleMute() {
        this.isMuted = !this.isMuted
      },
      handleLike() {
        this.$emit('like', this.video.id)
      },
      handleComment() {
        this.$emit('comment', this.video.id)
      },
      handleShare() {
        this.$emit('share', this.video.id)
      },
      toggleFollow() {
        this.$emit('follow', this.video.author.id)
        // 本地更新关注状态，实际应用中应该通过API请求后再更新
        this.video.author.isFollowed = !this.video.author.isFollowed
      },
      formatNumber(num) {
        if (num >= 10000) {
          return (num / 10000).toFixed(1) + 'w'
        } else if (num >= 1000) {
          return (num / 1000).toFixed(1) + 'k'
        }
        return num
      }
    }
  }
  </script>
  
  <style scoped>
  .video-player {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    transition: all 0.3s ease;
  }
  
  .video-player.pc-mode {
    max-width: 1200px;
    margin: 0 auto;
    border-radius: 12px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  }
  
  .video-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #000;
  }
  
  .video-element {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
  }
  
  .video-player.pc-mode .video-element {
    object-fit: contain;
  }
  
  .play-icon {
    position: absolute;
    font-size: 60px;
    color: rgba(255, 255, 255, 0.8);
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease;
    backdrop-filter: blur(5px);
  }
  
  .play-icon:hover {
    transform: scale(1.1);
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .video-controls {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(transparent, rgba(0, 0, 0, 0.7));
    padding: 20px;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .video-wrapper:hover .video-controls {
    opacity: 1;
  }
  
  .progress-bar {
    width: 100%;
    height: 4px;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 2px;
    cursor: pointer;
    position: relative;
    margin-bottom: 8px;
  }
  
  .progress {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background: #ff5e62;
    border-radius: 2px;
    transition: width 0.1s linear;
  }
  
  .time-display {
    color: #fff;
    font-size: 14px;
    text-align: right;
  }
  
  .video-info {
    position: absolute;
    bottom: 80px;
    left: 15px;
    width: 70%;
    color: #fff;
    text-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
    z-index: 2;
    transition: all 0.3s ease;
  }
  
  .video-info.pc-mode {
    position: static;
    width: 100%;
    padding: 20px;
    background: #fff;
    color: #333;
    text-shadow: none;
    border-radius: 0 0 12px 12px;
  }
  
  .video-title {
    font-size: 16px;
    margin-bottom: 5px;
    font-weight: bold;
    transition: color 0.3s ease;
  }
  
  .pc-mode .video-title {
    font-size: 20px;
  }
  
  .pc-mode .video-title:hover {
    color: #ff5e62;
  }
  
  .video-description {
    font-size: 14px;
    margin-bottom: 10px;
    opacity: 0.9;
    transition: opacity 0.3s ease;
  }
  
  .pc-mode .video-description {
    opacity: 0.8;
  }
  
  .pc-mode .video-description:hover {
    opacity: 1;
  }
  
  .author-info {
    display: flex;
    align-items: center;
    margin-top: 10px;
    transition: transform 0.3s ease;
  }
  
  .pc-mode .author-info:hover {
    transform: translateX(5px);
  }
  
  .author-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    border: 2px solid #fff;
    margin-right: 10px;
    transition: transform 0.3s ease;
  }
  
  .pc-mode .author-avatar {
    border-color: #ff5e62;
  }
  
  .pc-mode .author-avatar:hover {
    transform: scale(1.1);
  }
  
  .author-avatar img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
  }
  
  .author-avatar:hover img {
    transform: scale(1.1);
  }
  
  .author-name {
    font-weight: bold;
    margin-right: 10px;
    transition: color 0.3s ease;
  }
  
  .pc-mode .author-name:hover {
    color: #ff5e62;
  }
  
  .follow-btn {
    background: linear-gradient(135deg, #ff5e62, #ff9966);
    color: #fff;
    padding: 4px 16px;
    border-radius: 20px;
    font-size: 12px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .follow-btn:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(255, 94, 98, 0.3);
  }
  
  .follow-btn.followed {
    background: rgba(255, 255, 255, 0.2);
    border: 1px solid #fff;
  }
  
  .pc-mode .follow-btn.followed {
    background: #f5f5f5;
    border-color: #ddd;
    color: #666;
  }
  
  .interaction-btns {
    position: absolute;
    right: 15px;
    bottom: 80px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    z-index: 2;
  }
  
  .interaction-btns.pc-mode {
    position: static;
    flex-direction: row;
    justify-content: flex-end;
    padding: 20px;
    background: #fff;
    border-radius: 0 0 12px 12px;
  }
  
  .interaction-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .pc-mode .interaction-item {
    flex-direction: row;
    gap: 8px;
  }
  
  .interaction-icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
    transition: all 0.3s ease;
  }
  
  .pc-mode .interaction-icon {
    background: #f5f5f5;
    margin-bottom: 0;
  }
  
  .interaction-icon i {
    font-size: 20px;
    color: #fff;
    transition: all 0.3s ease;
  }
  
  .pc-mode .interaction-icon i {
    color: #666;
  }
  
  .interaction-icon.active {
    background: #ff5e62;
  }
  
  .interaction-icon.active i {
    color: #fff;
  }
  
  .interaction-count {
    font-size: 12px;
    color: #fff;
    transition: color 0.3s ease;
  }
  
  .pc-mode .interaction-count {
    color: #666;
  }
  
  .interaction-item:hover .interaction-icon {
    transform: scale(1.1);
    background: #ff5e62;
  }
  
  .interaction-item:hover .interaction-icon i {
    color: #fff;
  }
  
  .interaction-item:hover .interaction-count {
    color: #ff5e62;
  }
  
  /* 添加动画效果 */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .video-player {
    animation: fadeIn 0.5s ease forwards;
  }
  
  /* 响应式布局 */
  @media (max-width: 768px) {
    .video-info {
      width: 85%;
    }
    
    .interaction-btns {
      right: 10px;
    }
  }
  </style>