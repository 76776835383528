<template>
    <div class="uploader-container">
      <div class="uploader-header">
        <i class="el-icon-close close-icon" @click="$emit('close')"></i>
        <div class="header-title">上传视频</div>
        <div class="header-next" 
          :class="{ active: currentStep > 0 && selectedFile }"
          @click="nextStep"
        >
          {{ currentStep === 2 ? '发布' : '下一步' }}
        </div>
      </div>
      
      <div class="uploader-content">
        <!-- 步骤1：选择视频 -->
        <div class="step-container" v-if="currentStep === 0">
          <div class="upload-area" 
            @click="triggerUpload"
            :class="{ 'drag-over': isDragging }"
            @dragenter.prevent="isDragging = true"
            @dragleave.prevent="isDragging = false"
            @dragover.prevent
            @drop.prevent="handleDrop"
          >
            <div class="upload-content">
              <i class="el-icon-plus upload-icon"></i>
              <div class="upload-text">上传视频</div>
              <div class="upload-description">支持MP4、MOV等格式，拖拽文件到此处或点击上传</div>
            </div>
          </div>
          <input 
            type="file" 
            ref="fileInput" 
            accept="video/*" 
            style="display: none"
            @change="handleFileChange"
          >
          
          <div class="selected-video" v-if="selectedFile">
            <div class="video-preview">
              <video 
                ref="previewVideo" 
                :src="previewUrl" 
                controls 
                muted
              ></video>
              <div class="video-overlay">
                <i class="el-icon-refresh-right" @click="triggerUpload"></i>
              </div>
            </div>
            <div class="video-info">
              <div class="video-name">{{ selectedFile.name }}</div>
              <div class="video-size">{{ formatFileSize(selectedFile.size) }}</div>
              <div class="video-progress">
                <el-progress :percentage="uploadProgress" :show-text="false"></el-progress>
              </div>
            </div>
          </div>
        </div>
        
        <!-- 步骤2：裁剪和编辑 -->
        <div class="step-container" v-else-if="currentStep === 1">
          <div class="edit-preview">
            <div class="edit-video-container">
              <video 
                ref="editVideo" 
                :src="previewUrl" 
                controls 
                muted
                class="edit-video"
              ></video>
              
              <div class="edit-tools">
                <div class="edit-tool-item" 
                  v-for="(tool, index) in editTools" 
                  :key="index"
                  @click="handleToolClick(tool.type)"
                  :class="{ active: activeTool === tool.type }"
                >
                  <i :class="tool.icon"></i>
                  <span>{{ tool.name }}</span>
                </div>
              </div>
            </div>
            
            <div class="edit-panel" v-if="activeTool">
              <div class="panel-header">
                <span>{{ getToolName(activeTool) }}</span>
                <i class="el-icon-close" @click="activeTool = null"></i>
              </div>
              <div class="panel-content">
                <!-- 这里可以根据不同的工具类型显示不同的编辑面板 -->
                <div class="tool-content">
                  {{ getToolName(activeTool) }}编辑面板
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <!-- 步骤3：添加描述并发布 -->
        <div class="step-container" v-else-if="currentStep === 2">
          <div class="publish-preview">
            <div class="preview-container">
              <div class="video-cover">
                <video 
                  ref="publishVideo" 
                  :src="previewUrl" 
                  muted
                  class="cover-video"
                ></video>
                <div class="cover-edit-btn">
                  <i class="el-icon-picture"></i>
                  更换封面
                </div>
              </div>
              
              <div class="publish-form">
                <div class="form-item">
                  <div class="form-label">标题</div>
                  <el-input 
                    v-model="videoTitle" 
                    type="text"
                    maxlength="50"
                    placeholder="给你的视频添加一个吸引人的标题"
                    show-word-limit
                  ></el-input>
                </div>
                
                <div class="form-item">
                  <div class="form-label">描述</div>
                  <el-input 
                    v-model="videoDescription" 
                    type="textarea"
                    maxlength="200"
                    placeholder="添加视频描述，让更多人找到你的视频"
                    :rows="4"
                    show-word-limit
                  ></el-input>
                </div>
                
                <div class="form-item">
                  <div class="form-label">分类标签</div>
                  <el-select
                    v-model="selectedTags"
                    multiple
                    filterable
                    allow-create
                    default-first-option
                    placeholder="选择或创建标签"
                  >
                    <el-option
                      v-for="item in tagOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </div>
                
                <div class="form-item">
                  <div class="form-label">隐私设置</div>
                  <el-radio-group v-model="privacyOption">
                    <el-radio :label="'public'">公开</el-radio>
                    <el-radio :label="'friends'">好友可见</el-radio>
                    <el-radio :label="'private'">仅自己可见</el-radio>
                  </el-radio-group>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'VideoUploader',
    data() {
      return {
        currentStep: 0,
        selectedFile: null,
        previewUrl: '',
        videoTitle: '',
        videoDescription: '',
        selectedTags: [],
        privacyOption: 'public',
        isDragging: false,
        uploadProgress: 0,
        activeTool: null,
        editTools: [
          { type: 'trim', name: '裁剪', icon: 'el-icon-scissors' },
          { type: 'filter', name: '滤镜', icon: 'el-icon-picture' },
          { type: 'music', name: '音乐', icon: 'el-icon-headset' },
          { type: 'effect', name: '特效', icon: 'el-icon-magic-stick' }
        ],
        tagOptions: [
          { value: 'funny', label: '搞笑' },
          { value: 'music', label: '音乐' },
          { value: 'dance', label: '舞蹈' },
          { value: 'food', label: '美食' },
          { value: 'travel', label: '旅行' },
          { value: 'pet', label: '宠物' },
          { value: 'game', label: '游戏' },
          { value: 'tech', label: '科技' }
        ]
      }
    },
    methods: {
      triggerUpload() {
        this.$refs.fileInput.click()
      },
      handleFileChange(e) {
        const file = e.target.files[0]
        if (!file) return
        
        // 检查文件类型
        if (!file.type.includes('video/')) {
          this.$message.error('请选择视频文件')
          return
        }
        
        // 限制文件大小（示例：100MB）
        const maxSize = 100 * 1024 * 1024
        if (file.size > maxSize) {
          this.$message.error('文件大小不能超过100MB')
          return
        }
        
        this.selectedFile = file
        this.previewUrl = URL.createObjectURL(file)
        this.simulateUpload()
      },
      handleDrop(e) {
        this.isDragging = false
        const file = e.dataTransfer.files[0]
        if (file && file.type.includes('video/')) {
          this.handleFile(file)
        }
      },
      handleFile(file) {
        if (!file.type.includes('video/')) {
          this.$message.error('请选择视频文件')
          return
        }
        
        const maxSize = 100 * 1024 * 1024
        if (file.size > maxSize) {
          this.$message.error('文件大小不能超过100MB')
          return
        }
        
        this.selectedFile = file
        this.previewUrl = URL.createObjectURL(file)
        this.simulateUpload()
      },
      simulateUpload() {
        this.uploadProgress = 0
        const interval = setInterval(() => {
          if (this.uploadProgress >= 100) {
            clearInterval(interval)
            return
          }
          this.uploadProgress += 10
        }, 500)
      },
      handleToolClick(toolType) {
        this.activeTool = this.activeTool === toolType ? null : toolType
      },
      getToolName(type) {
        const tool = this.editTools.find(t => t.type === type)
        return tool ? tool.name : ''
      },
      nextStep() {
        if (!this.selectedFile && this.currentStep === 0) {
          this.$message.error('请先选择一个视频文件')
          return
        }
        
        if (this.currentStep === 2) {
          this.publishVideo()
        } else {
          this.currentStep++
        }
      },
      publishVideo() {
        if (!this.videoTitle.trim()) {
          this.$message.error('请输入视频标题')
          return
        }
        
        // 在实际应用中，这里应该调用API上传视频
        // 这里我们模拟上传成功
        const mockUploadSuccess = () => {
          // 模拟生成的新视频对象
          const newVideo = {
            id: Date.now(),
            url: this.previewUrl,
            cover: this.previewUrl, // 实际应用中应该生成封面
            title: this.videoTitle,
            description: this.videoDescription,
            author: {
              id: 999, // 当前用户ID
              name: '当前用户', // 当前用户名
              avatar: 'https://example.com/current-user-avatar.jpg',
              isFollowed: false
            },
            likes: 0,
            comments: 0,
            shares: 0,
            isLiked: false,
            tags: this.selectedTags,
            privacy: this.privacyOption
          }
          
          // 通知父组件上传成功
          this.$emit('upload-success', newVideo)
          
          // 清空表单
          this.resetForm()
        }
        
        // 模拟上传延迟
        this.$message({
          message: '视频上传中...',
          type: 'info'
        })
        
        setTimeout(() => {
          this.$message({
            message: '视频上传成功!',
            type: 'success'
          })
          mockUploadSuccess()
        }, 1500)
      },
      resetForm() {
        this.currentStep = 0
        this.selectedFile = null
        this.previewUrl = ''
        this.videoTitle = ''
        this.videoDescription = ''
        this.selectedTags = []
        this.privacyOption = 'public'
        
        // 清空文件选择
        if (this.$refs.fileInput) {
          this.$refs.fileInput.value = ''
        }
      },
      formatFileSize(size) {
        if (size < 1024) {
          return `${size}B`
        } else if (size < 1024 * 1024) {
          return `${(size / 1024).toFixed(2)}KB`
        } else if (size < 1024 * 1024 * 1024) {
          return `${(size / (1024 * 1024)).toFixed(2)}MB`
        } else {
          return `${(size / (1024 * 1024 * 1024)).toFixed(2)}GB`
        }
      }
    }
  }
  </script>
  
  <style scoped>
  .uploader-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    max-width: 1200px;
    height: 80vh;
    background-color: #fff;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
    overflow: hidden;
  }
  
  .uploader-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    padding: 0 20px;
    border-bottom: 1px solid #eee;
    background: linear-gradient(to right, #f8f9fa, #fff);
  }
  
  .close-icon {
    font-size: 24px;
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  
  .close-icon:hover {
    transform: rotate(90deg);
  }
  
  .header-title {
    font-size: 20px;
    font-weight: bold;
    background: linear-gradient(45deg, #ff5e62, #ff9966);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .header-next {
    color: #999;
    cursor: default;
    padding: 8px 20px;
    border-radius: 20px;
    transition: all 0.3s ease;
  }
  
  .header-next.active {
    color: #fff;
    background: linear-gradient(45deg, #ff5e62, #ff9966);
    cursor: pointer;
    box-shadow: 0 2px 8px rgba(255, 94, 98, 0.3);
  }
  
  .header-next.active:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(255, 94, 98, 0.4);
  }
  
  .uploader-content {
    flex: 1;
    overflow-y: auto;
    padding: 30px;
    background-color: #f8f9fa;
  }
  
  .step-container {
    height: 100%;
    animation: fadeIn 0.3s ease;
  }
  
  .upload-area {
    border: 2px dashed #ddd;
    border-radius: 12px;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease;
    background-color: #fff;
    position: relative;
    overflow: hidden;
  }
  
  .upload-area.drag-over {
    border-color: #ff5e62;
    background-color: rgba(255, 94, 98, 0.05);
    transform: scale(1.02);
  }
  
  .upload-content {
    text-align: center;
    z-index: 1;
  }
  
  .upload-icon {
    font-size: 48px;
    color: #ff5e62;
    margin-bottom: 15px;
    transition: transform 0.3s ease;
  }
  
  .upload-area:hover .upload-icon {
    transform: scale(1.1);
  }
  
  .upload-text {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #333;
  }
  
  .upload-description {
    font-size: 16px;
    color: #999;
  }
  
  .selected-video {
    margin-top: 30px;
    display: flex;
    background: #fff;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    transition: transform 0.3s ease;
  }
  
  .selected-video:hover {
    transform: translateY(-2px);
  }
  
  .video-preview {
    width: 300px;
    height: 200px;
    background-color: #000;
    position: relative;
  }
  
  .video-preview video {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  
  .video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .video-preview:hover .video-overlay {
    opacity: 1;
  }
  
  .video-overlay i {
    color: #fff;
    font-size: 32px;
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  
  .video-overlay i:hover {
    transform: scale(1.1);
  }
  
  .video-info {
    flex: 1;
    padding: 20px;
  }
  
  .video-name {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #333;
  }
  
  .video-size {
    color: #999;
    font-size: 14px;
    margin-bottom: 15px;
  }
  
  .video-progress {
    width: 100%;
  }
  
  .edit-preview {
    display: flex;
    gap: 20px;
    height: 100%;
  }
  
  .edit-video-container {
    flex: 1;
    background: #fff;
    border-radius: 12px;
    padding: 20px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  }
  
  .edit-video {
    width: 100%;
    height: 400px;
    object-fit: contain;
    background-color: #000;
    border-radius: 8px;
  }
  
  .edit-tools {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
  }
  
  .edit-tool-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    padding: 10px;
    border-radius: 8px;
    transition: all 0.3s ease;
  }
  
  .edit-tool-item:hover {
    background-color: rgba(255, 94, 98, 0.1);
  }
  
  .edit-tool-item.active {
    background-color: rgba(255, 94, 98, 0.2);
    color: #ff5e62;
  }
  
  .edit-tool-item i {
    font-size: 28px;
    margin-bottom: 8px;
  }
  
  .edit-panel {
    width: 300px;
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    display: flex;
    flex-direction: column;
  }
  
  .panel-header {
    padding: 15px 20px;
    border-bottom: 1px solid #eee;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .panel-header i {
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  
  .panel-header i:hover {
    transform: rotate(90deg);
  }
  
  .panel-content {
    flex: 1;
    padding: 20px;
    overflow-y: auto;
  }
  
  .publish-preview {
    height: 100%;
    overflow-y: auto;
  }
  
  .preview-container {
    display: flex;
    gap: 30px;
  }
  
  .video-cover {
    width: 400px;
    height: 300px;
    position: relative;
    background-color: #000;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  .cover-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .cover-edit-btn {
    position: absolute;
    bottom: 15px;
    right: 15px;
    background: rgba(0, 0, 0, 0.6);
    color: #fff;
    padding: 8px 15px;
    border-radius: 20px;
    font-size: 14px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .cover-edit-btn:hover {
    background: rgba(0, 0, 0, 0.8);
    transform: translateY(-2px);
  }
  
  .cover-edit-btn i {
    margin-right: 5px;
  }
  
  .publish-form {
    flex: 1;
    background: #fff;
    border-radius: 12px;
    padding: 30px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  }
  
  .form-item {
    margin-bottom: 25px;
  }
  
  .form-label {
    font-weight: bold;
    margin-bottom: 10px;
    color: #333;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* 自定义滚动条样式 */
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }
  
  ::-webkit-scrollbar-thumb {
    background: #ddd;
    border-radius: 4px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #ccc;
  }
  </style>