<template>
  <div class="register-container">
    <div class="animated-background">
      <div class="circle"></div>
      <div class="circle"></div>
      <div class="circle"></div>
      <div class="circle"></div>
      <div class="particles">
        <div v-for="n in 20" :key="n" class="particle"></div>
      </div>
      <div class="dna-helix">
        <div v-for="n in 10" :key="`dna-${n}`" class="dna-strand" 
            :style="{ animationDelay: `${n * 0.2}s`, top: `${n * 10}%` }"></div>
      </div>
      <div class="medical-icons">
        <div v-for="icon in medicalIcons" :key="icon.id" class="medical-icon"
            :class="icon.class" :style="icon.style"></div>
      </div>
      <div class="medical-pulse">
        <div class="pulse-line"></div>
      </div>
      <!-- 分子背景 -->
      <div class="molecule-bg">
        <div v-for="n in 5" :key="`molecule-${n}`" class="molecule"
            :style="{ top: `${n * 18}%`, left: `${n * 20}%`, animationDelay: `${n * 0.5}s` }"></div>
      </div>
      <!-- 医学符号背景 -->
      <div class="medical-symbols">
        <div v-for="n in 3" :key="`symbol-${n}`" class="medical-symbol"
            :class="`symbol-${n}`"></div>
      </div>
      <!-- 科技网格 -->
      <div class="tech-grid"></div>
      <!-- 医疗背景图形 -->
      <div class="medical-bg-shapes">
        <div class="shape shape-1"></div>
        <div class="shape shape-2"></div>
        <div class="shape shape-3"></div>
      </div>
      <!-- 渐变光效 -->
      <div class="glow-effect"></div>
      <!-- 浮动医疗元素 -->
      <div class="floating-elements">
        <div class="medical-element heartbeat-icon"></div>
        <div class="medical-element hospital-icon"></div>
        <div class="medical-element bandaid-icon"></div>
        <div class="medical-element virus-icon"></div>
        <div class="medical-element brain-icon"></div>
      </div>
    </div>
    <div class="register-content">
      <div class="register-left">
        <div class="ai-features">
          <div class="title-container">
            <div class="title-icon"></div>
            <h1 class="animate-title">医路智通</h1>
          </div>
          <p class="subtitle animate-subtitle">智慧健康，尽在掌握</p>
          <div class="feature-list">
            <div class="feature-item" v-for="(feature, index) in features" :key="index"
                 :style="{ animationDelay: index * 0.2 + 's' }">
              <el-icon><component :is="feature.icon" /></el-icon>
              <div class="feature-content">
                <span class="feature-title">{{ feature.text }}</span>
                <span class="feature-desc">{{ feature.description }}</span>
              </div>
            </div>
          </div>
          <div class="ai-highlight">
            <div class="highlight-text">
              <span class="highlight-icon">💊</span>
              <span>科技医疗 · 健康生活 · 智慧医护</span>
            </div>
          </div>
        </div>
      </div>
      <div class="register-right">
        <el-form :model="registerForm" :rules="rules" ref="registerForm" class="register-form">
          <h2 class="register-title animate-title">创建账号</h2>
          <p class="register-subtitle animate-subtitle">加入医路智通健康管理平台</p>
          <el-form-item prop="userName">
            <el-input v-model="registerForm.userName" placeholder="用户名" class="animate-input">
              <template #prefix>
                <el-icon><User /></el-icon>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item prop="nickName">
            <el-input v-model="registerForm.nickName" placeholder="昵称" class="animate-input">
              <template #prefix>
                <el-icon><Avatar /></el-icon>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input v-model="registerForm.password" type="password" placeholder="密码" class="animate-input">
              <template #prefix>
                <el-icon><Lock /></el-icon>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item prop="confirmPassword">
            <el-input v-model="registerForm.confirmPassword" type="password" placeholder="确认密码" class="animate-input">
              <template #prefix>
                <el-icon><Lock /></el-icon>
              </template>
            </el-input>
          </el-form-item>
          <div class="form-options">
            <el-checkbox v-model="agreeTerms">我已阅读并同意服务条款和隐私政策</el-checkbox>
          </div>
          <div class="button-group">
            <el-button type="primary" @click="handleRegister" class="register-button animate-button" :loading="loading">
              <span class="button-content" v-if="!loading">
                <el-icon><Plus /></el-icon>
                <span>创建账号</span>
              </span>
            </el-button>
            <el-button @click="handleLogin" class="login-button animate-button" :disabled="loading">
              <span class="button-content">
                <el-icon><Back /></el-icon>
                <span>返回登录</span>
              </span>
            </el-button>
          </div>
        </el-form>
      </div>
    </div>
    <div class="footer-info">
      <span>© 2025 医路智通 - 智慧医疗服务平台 版权所有</span>
    </div>
  </div>
</template>

<script>
import { User, Lock, ChatDotRound, Message, VideoCamera, Cpu, Plus, Back, Avatar } from '@element-plus/icons-vue'
import { register } from '@/api/user'

export default {
  name: 'Register',
  components: {
    User,
    Lock,
    ChatDotRound,
    Message,
    VideoCamera,
    Cpu,
    Plus,
    Back,
    Avatar
  },
  data() {
    const validatePass2 = (rule, value, callback) => {
      if (value !== this.registerForm.password) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      registerForm: {
        userName: '',
        nickName: '',
        password: '',
        confirmPassword: ''
      },
      agreeTerms: false,
      loading: false,
      rules: {
        userName: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
          { max: 64, message: '用户名不能超过64个字符', trigger: 'blur' }
        ],
        nickName: [
          { required: true, message: '请输入昵称', trigger: 'blur' },
          { max: 64, message: '昵称不能超过64个字符', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 5, max: 20, message: '密码长度必须在5-20个字符之间', trigger: 'blur' }
        ],
        confirmPassword: [
          { required: true, message: '请再次输入密码', trigger: 'blur' },
          { validator: validatePass2, trigger: 'blur' }
        ]
      },
      features: [
        { 
          icon: 'ChatDotRound', 
          text: '电子病历',
          description: '数字化管理个人健康记录，随时随地查询'
        },
        { 
          icon: 'Message', 
          text: '预约挂号',
          description: '线上预约全国知名医院，避免排队等待'
        },
        { 
          icon: 'VideoCamera', 
          text: '专家讲堂',
          description: '顶级医学专家分享健康知识和预防保健方案'
        },
        { 
          icon: 'Cpu', 
          text: '智能监测',
          description: '连接智能设备，实时监测身体健康指标'
        }
      ],
      medicalIcons: [
        { id: 1, class: 'heart-beat', style: { top: '15%', left: '10%' } },
        { id: 2, class: 'pill', style: { top: '30%', right: '15%' } },
        { id: 3, class: 'stethoscope', style: { bottom: '20%', left: '20%' } },
        { id: 4, class: 'pulse', style: { top: '70%', right: '10%' } },
        { id: 5, class: 'medical-cross', style: { top: '40%', left: '50%' } }
      ]
    }
  },
  methods: {
    handleRegister() {
      if (!this.agreeTerms) {
        this.$message.warning('请先阅读并同意服务条款和隐私政策')
        return
      }
      
      this.$refs.registerForm.validate(async (valid) => {
        if (valid) {
          try {
            this.loading = true
            await register({
              userName: this.registerForm.userName,
              nickName: this.registerForm.nickName,
              password: this.registerForm.password
            })
            
            this.$message.success('注册成功，请登录')
            // 跳转到登录页
            setTimeout(() => {
              this.$router.push('/login')
            }, 1500)
          } catch (error) {
            console.error('注册失败：', error)
          } finally {
            this.loading = false
          }
        }
      })
    },
    handleLogin() {
      this.$router.push('/login')
    }
  }
}
</script>

<style scoped>
.register-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(135deg, #00796b 0%, #004d40 100%), url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100"><path d="M20 50 A30 30 0 0 1 80 50 A30 30 0 0 1 20 50 Z" fill="none" stroke="%23B2EBF2" stroke-width="0.5" opacity="0.2"/></svg>');
  background-blend-mode: overlay;
  position: relative;
  overflow: hidden;
}

.animated-background {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.circle {
  position: absolute;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
  animation: float 15s infinite;
}

.circle:nth-child(1) {
  width: 300px;
  height: 300px;
  top: -150px;
  left: -150px;
  animation-delay: 0s;
}

.circle:nth-child(2) {
  width: 200px;
  height: 200px;
  top: 50%;
  right: -100px;
  animation-delay: 5s;
}

.circle:nth-child(3) {
  width: 150px;
  height: 150px;
  bottom: -75px;
  left: 50%;
  animation-delay: 10s;
}

.circle:nth-child(4) {
  width: 250px;
  height: 250px;
  top: 25%;
  right: 25%;
  animation-delay: 7s;
}

.particles {
  position: absolute;
  width: 100%;
  height: 100%;
}

.particle {
  position: absolute;
  width: 4px;
  height: 4px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  animation: particleFloat 20s infinite linear;
}

.particle:nth-child(1) { left: 10%; top: 20%; animation-delay: 0s; }
.particle:nth-child(2) { left: 20%; top: 40%; animation-delay: 2s; }
.particle:nth-child(3) { left: 30%; top: 60%; animation-delay: 4s; }
.particle:nth-child(4) { left: 40%; top: 80%; animation-delay: 6s; }
.particle:nth-child(5) { left: 50%; top: 30%; animation-delay: 8s; }
.particle:nth-child(6) { left: 60%; top: 50%; animation-delay: 10s; }
.particle:nth-child(7) { left: 70%; top: 70%; animation-delay: 12s; }
.particle:nth-child(8) { left: 80%; top: 90%; animation-delay: 14s; }
.particle:nth-child(9) { left: 90%; top: 40%; animation-delay: 16s; }
.particle:nth-child(10) { left: 95%; top: 60%; animation-delay: 18s; }

@keyframes particleFloat {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 0;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(-100vh) rotate(360deg);
    opacity: 0;
  }
}

@keyframes float {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }
  50% {
    transform: translate(30px, 30px) rotate(180deg);
  }
  100% {
    transform: translate(0, 0) rotate(360deg);
  }
}

.register-content {
  display: flex;
  width: 1000px;
  height: 600px;
  background: rgba(255, 255, 255, 0.95);
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.2);
  position: relative;
  backdrop-filter: blur(10px);
  animation: fadeIn 0.8s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.register-left {
  flex: 1;
  background: linear-gradient(135deg, #1E88E5 0%, #00796b 100%);
  padding: 40px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.register-left::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(45deg, transparent, rgba(255, 255, 255, 0.1), transparent);
  transform: translateX(-100%);
  animation: shine 3s infinite;
}

@keyframes shine {
  100% {
    transform: translateX(100%);
  }
}

.title-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.title-icon {
  width: 40px;
  height: 40px;
  margin-right: 15px;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" fill="%23FFFFFF"><path d="M25 2 C12 2, 2 12, 2 25 C2 38, 12 48, 25 48 C38 48, 48 38, 48 25 C48 12, 38 2, 25 2 Z M25 44 C14 44, 6 36, 6 25 C6 14, 14 6, 25 6 C36 6, 44 14, 44 25 C44 36, 36 44, 25 44 Z" stroke="%2380DEEA" stroke-width="1"/><path d="M25 14 L25 36 M14 25 L36 25" stroke="%23FFFFFF" stroke-width="2"/><circle cx="25" cy="25" r="5" fill="%2380DEEA" opacity="0.7"/></svg>');
  background-size: contain;
  background-repeat: no-repeat;
  animation: rotateIcon 10s infinite linear;
}

@keyframes rotateIcon {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.register-left h1 {
  font-size: 46px;
  margin-bottom: 5px;
  font-weight: 700;
  animation: slideInLeft 0.8s ease-out;
  background: linear-gradient(45deg, #fff, #e3f2fd);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 2px 10px rgba(0, 255, 255, 0.15);
  letter-spacing: 2px;
  position: relative;
}

.register-left h1::first-letter {
  font-size: 52px;
  color: #80DEEA;
}

.subtitle {
  font-size: 20px;
  margin-bottom: 40px;
  opacity: 0.9;
  animation: slideInLeft 0.8s ease-out 0.2s backwards;
}

.feature-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.feature-item {
  display: flex;
  align-items: flex-start;
  gap: 15px;
  font-size: 18px;
  animation: slideInLeft 0.8s ease-out backwards;
  transition: all 0.3s ease;
  padding: 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.1);
}

.feature-item:hover {
  transform: translateX(10px);
  background: rgba(255, 255, 255, 0.2);
}

.feature-item .el-icon {
  font-size: 24px;
  margin-top: 3px;
}

.feature-content {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.feature-title {
  font-weight: 500;
}

.feature-desc {
  font-size: 14px;
  opacity: 0.8;
}

.ai-highlight {
  margin-top: 40px;
  padding: 15px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  animation: slideInLeft 0.8s ease-out 1s backwards;
}

.highlight-text {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 16px;
}

.highlight-icon {
  font-size: 20px;
}

.register-right {
  flex: 1;
  padding: 40px;
  display: flex;
  align-items: center;
}

.register-form {
  width: 100%;
}

.register-title {
  font-size: 32px;
  color: #00796b;
  margin-bottom: 10px;
  text-align: center;
  animation: slideInRight 0.8s ease-out;
  background: linear-gradient(45deg, #004d40, #00bfa5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.register-subtitle {
  text-align: center;
  color: #666;
  margin-bottom: 30px;
  animation: slideInRight 0.8s ease-out 0.2s backwards;
}

.form-options {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  animation: slideInRight 0.8s ease-out 0.6s backwards;
}

.animate-input {
  animation: slideInRight 0.8s ease-out backwards;
}

.animate-input:nth-child(1) {
  animation-delay: 0.4s;
}

.animate-input:nth-child(2) {
  animation-delay: 0.6s;
}

.animate-input:nth-child(3) {
  animation-delay: 0.8s;
}

.animate-button {
  animation: slideInRight 0.8s ease-out backwards;
}

.animate-button:nth-child(1) {
  animation-delay: 1s;
}

.animate-button:nth-child(2) {
  animation-delay: 1.2s;
}

@keyframes slideInLeft {
  from {
    opacity: 0;
    transform: translateX(-30px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideInRight {
  from {
    opacity: 0;
    transform: translateX(30px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.button-group {
  display: flex;
  flex-direction: column;
  width: 100%;
  animation: slideInRight 0.8s ease-out 1s backwards;
  margin-top: 10px;
}

.register-button {
  width: 100%;
  height: 45px;
  margin-bottom: 15px;
  background: linear-gradient(135deg, #00bfa5 0%, #00796b 100%);
  border: none;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
}

.button-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.register-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.2), transparent);
  transition: 0.5s;
}

.register-button:hover::before {
  left: 100%;
}

.register-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(0, 191, 165, 0.4);
}

.login-button {
  width: 100%;
  height: 45px;
  border: 1px solid #00796b;
  color: #00796b;
  transition: all 0.3s ease;
  border-radius: 8px;
}

.login-button:hover {
  background: #00796b;
  color: white;
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(0, 121, 107, 0.4);
}

:deep(.el-input__wrapper) {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.05);
  height: 45px;
  transition: all 0.3s ease;
}

:deep(.el-input__wrapper:hover) {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  transform: translateY(-2px);
}

:deep(.el-input__inner) {
  height: 45px;
}

.dna-helix {
  position: absolute;
  width: 100%;
  height: 100%;
}

.dna-strand {
  position: absolute;
  width: 100%;
  height: 15px;
  background: linear-gradient(90deg, transparent 30%, rgba(0, 255, 255, 0.2) 40%, rgba(0, 255, 255, 0.3) 50%, rgba(0, 255, 255, 0.2) 60%, transparent 70%);
  animation: dnaMove 15s infinite linear;
  left: -100%;
}

@keyframes dnaMove {
  0% {
    transform: translateX(0) rotateZ(0deg);
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    transform: translateX(200%) rotateZ(5deg);
    opacity: 0;
  }
}

.medical-icons {
  position: absolute;
  width: 100%;
  height: 100%;
}

.medical-icon {
  position: absolute;
  width: 40px;
  height: 40px;
  background-size: contain;
  background-repeat: no-repeat;
  opacity: 0.6;
  animation: pulseIcon 4s infinite ease-in-out;
}

.heart-beat {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23ffffff"><path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"/></svg>');
}

.pill {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23ffffff"><path d="M19 3h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm0 4c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm6 12H6v-1.4c0-2 4-3.1 6-3.1s6 1.1 6 3.1V19z"/></svg>');
}

.stethoscope {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23ffffff"><path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"/></svg>');
}

.pulse {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23ffffff"><path d="M3.5 18.49l6-6.01 4 4L22 6.92l-1.41-1.41-7.09 7.97-4-4L2 16.99z"/></svg>');
}

.medical-cross {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23ffffff"><path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"/></svg>');
}

.medical-pulse {
  position: absolute;
  bottom: 50px;
  left: 0;
  width: 100%;
  height: 50px;
  overflow: hidden;
}

.pulse-line {
  position: absolute;
  width: 200%;
  height: 100%;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="1200" height="60" viewBox="0 0 1200 60" fill="none"><path d="M0 30 L50 30 L70 10 L90 50 L110 30 L130 30 L150 20 L170 40 L190 30 L210 30 L230 10 L250 50 L270 30 L290 30 L310 20 L330 40 L350 30 L370 30 L390 10 L410 50 L430 30 L450 30 L470 20 L490 40 L510 30 L530 30 L550 10 L570 50 L590 30 L610 30 L630 20 L650 40 L670 30 L690 30 L710 10 L730 50 L750 30 L770 30 L790 20 L810 40 L830 30 L850 30 L870 10 L890 50 L910 30 L930 30 L950 20 L970 40 L990 30 L1010 30 L1030 10 L1050 50 L1070 30 L1090 30 L1110 20 L1130 40 L1150 30 L1170 30 L1190 10 L1210 50" stroke="%2300BFA5" stroke-width="2"/></svg>');
  animation: pulseMove 20s infinite linear;
}

@keyframes pulseMove {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

@keyframes pulseIcon {
  0% {
    transform: scale(1);
    opacity: 0.4;
  }
  50% {
    transform: scale(1.2);
    opacity: 0.8;
  }
  100% {
    transform: scale(1);
    opacity: 0.4;
  }
}

.molecule-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.4;
}

.molecule {
  position: absolute;
  width: 120px;
  height: 120px;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" fill="none" stroke="%23E0F7FA"><circle cx="50" cy="50" r="10" fill="%2380DEEA" stroke-width="1"/><circle cx="50" cy="20" r="6" fill="%2380DEEA" stroke-width="1"/><circle cx="80" cy="50" r="6" fill="%2380DEEA" stroke-width="1"/><circle cx="50" cy="80" r="6" fill="%2380DEEA" stroke-width="1"/><circle cx="20" cy="50" r="6" fill="%2380DEEA" stroke-width="1"/><line x1="50" y1="40" x2="50" y2="26" stroke-width="2"/><line x1="60" y1="50" x2="74" y2="50" stroke-width="2"/><line x1="50" y1="60" x2="50" y2="74" stroke-width="2"/><line x1="40" y1="50" x2="26" y2="50" stroke-width="2"/></svg>');
  background-size: contain;
  background-repeat: no-repeat;
  animation: rotateMolecule 20s infinite linear;
  opacity: 0.7;
}

@keyframes rotateMolecule {
  0% {
    transform: rotate(0deg) scale(1);
  }
  50% {
    transform: rotate(180deg) scale(1.2);
  }
  100% {
    transform: rotate(360deg) scale(1);
  }
}

.medical-symbols {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.3;
}

.medical-symbol {
  position: absolute;
  width: 80px;
  height: 80px;
  background-size: contain;
  background-repeat: no-repeat;
  animation: floatSymbol 15s infinite ease-in-out;
}

.symbol-1 {
  top: 15%;
  right: 10%;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80"><path d="M40 10 L40 70 M15 40 L65 40" stroke="%23B2EBF2" stroke-width="4"/></svg>');
  animation-delay: 0s;
}

.symbol-2 {
  bottom: 20%;
  left: 15%;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80"><path d="M20 60 C20 20, 60 60, 60 20" stroke="%23B2EBF2" stroke-width="4" fill="none"/><circle cx="20" cy="60" r="4" fill="%23B2EBF2"/><circle cx="60" cy="20" r="4" fill="%23B2EBF2"/></svg>');
  animation-delay: 3s;
}

.symbol-3 {
  top: 40%;
  right: 25%;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80"><circle cx="40" cy="40" r="30" stroke="%23B2EBF2" stroke-width="4" fill="none"/><path d="M40 10 L40 20 M40 60 L40 70 M10 40 L20 40 M60 40 L70 40" stroke="%23B2EBF2" stroke-width="4"/></svg>');
  animation-delay: 6s;
}

@keyframes floatSymbol {
  0% {
    transform: translate(0, 0) rotate(0deg);
    opacity: 0.2;
  }
  33% {
    transform: translate(10px, -10px) rotate(5deg);
    opacity: 0.4;
  }
  66% {
    transform: translate(-10px, 10px) rotate(-5deg);
    opacity: 0.3;
  }
  100% {
    transform: translate(0, 0) rotate(0deg);
    opacity: 0.2;
  }
}

.tech-grid {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100"><line x1="0" y1="20" x2="100" y2="20" stroke="%23B2EBF2" stroke-width="0.2"/><line x1="0" y1="40" x2="100" y2="40" stroke="%23B2EBF2" stroke-width="0.2"/><line x1="0" y1="60" x2="100" y2="60" stroke="%23B2EBF2" stroke-width="0.2"/><line x1="0" y1="80" x2="100" y2="80" stroke="%23B2EBF2" stroke-width="0.2"/><line x1="20" y1="0" x2="20" y2="100" stroke="%23B2EBF2" stroke-width="0.2"/><line x1="40" y1="0" x2="40" y2="100" stroke="%23B2EBF2" stroke-width="0.2"/><line x1="60" y1="0" x2="60" y2="100" stroke="%23B2EBF2" stroke-width="0.2"/><line x1="80" y1="0" x2="80" y2="100" stroke="%23B2EBF2" stroke-width="0.2"/></svg>');
  opacity: 0.1;
}

.medical-bg-shapes {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.2;
}

.shape {
  position: absolute;
  opacity: 0.3;
  animation: shapePulse 12s infinite ease-in-out;
  background-size: contain;
  background-repeat: no-repeat;
}

.shape-1 {
  top: 10%;
  left: 5%;
  width: 300px;
  height: 300px;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="300" height="300" viewBox="0 0 300 300"><path d="M150 50 A100 100 0 0 1 250 150 A100 100 0 0 1 150 250 A100 100 0 0 1 50 150 A100 100 0 0 1 150 50 Z" fill="none" stroke="%23B2EBF2" stroke-width="1" stroke-dasharray="5,5"/><path d="M150 70 A80 80 0 0 1 230 150 A80 80 0 0 1 150 230 A80 80 0 0 1 70 150 A80 80 0 0 1 150 70 Z" fill="none" stroke="%23B2EBF2" stroke-width="1" stroke-dasharray="5,5"/></svg>');
  animation-delay: 0s;
}

.shape-2 {
  bottom: 5%;
  right: 10%;
  width: 250px;
  height: 250px;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="250" height="250" viewBox="0 0 250 250"><rect x="50" y="50" width="150" height="150" rx="20" fill="none" stroke="%23B2EBF2" stroke-width="1"/><rect x="75" y="75" width="100" height="100" rx="10" fill="none" stroke="%23B2EBF2" stroke-width="1"/><rect x="100" y="100" width="50" height="50" rx="5" fill="none" stroke="%23B2EBF2" stroke-width="1"/></svg>');
  animation-delay: 4s;
}

.shape-3 {
  top: 40%;
  right: 20%;
  width: 200px;
  height: 200px;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="200" height="200" viewBox="0 0 200 200"><path d="M20 100 L60 40 L140 40 L180 100 L140 160 L60 160 Z" fill="none" stroke="%23B2EBF2" stroke-width="1"/><path d="M60 100 L80 70 L120 70 L140 100 L120 130 L80 130 Z" fill="none" stroke="%23B2EBF2" stroke-width="1"/><circle cx="100" cy="100" r="15" fill="none" stroke="%23B2EBF2" stroke-width="1"/></svg>');
  animation-delay: 8s;
}

@keyframes shapePulse {
  0% {
    transform: scale(1) rotate(0deg);
    opacity: 0.2;
  }
  50% {
    transform: scale(1.1) rotate(5deg);
    opacity: 0.4;
  }
  100% {
    transform: scale(1) rotate(0deg);
    opacity: 0.2;
  }
}

.glow-effect {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle at 30% 40%, rgba(0, 255, 255, 0.1) 0%, rgba(0, 0, 0, 0) 50%);
  animation: glowPulse 8s infinite ease-in-out;
}

@keyframes glowPulse {
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 0.3;
  }
}

.floating-elements {
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.medical-element {
  position: absolute;
  width: 50px;
  height: 50px;
  background-size: contain;
  background-repeat: no-repeat;
  opacity: 0.4;
  animation: floatElement 20s infinite ease-in-out;
}

.heartbeat-icon {
  top: 25%;
  left: 15%;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="%23B2EBF2"><path d="M10 25 L20 25 L25 15 L30 35 L35 25 L45 25" stroke="%23B2EBF2" stroke-width="2" fill="none"/></svg>');
  animation-delay: 2s;
}

.hospital-icon {
  top: 70%;
  right: 20%;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="%23B2EBF2"><rect x="10" y="15" width="30" height="30" fill-opacity="0.5" stroke="%23B2EBF2" stroke-width="1"/><path d="M25 5 L25 15" stroke="%23B2EBF2" stroke-width="1"/><path d="M15 45 L15 35 M35 45 L35 35" stroke="%23B2EBF2" stroke-width="1"/><path d="M20 25 L30 25 M25 20 L25 30" stroke="white" stroke-width="1.5"/><rect x="17" y="5" width="16" height="10" fill-opacity="0.5" stroke="%23B2EBF2" stroke-width="1"/></svg>');
  animation-delay: 5s;
}

.bandaid-icon {
  top: 40%;
  left: 80%;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="%23B2EBF2"><rect x="15" y="15" width="20" height="20" transform="rotate(45, 25, 25)" fill-opacity="0.5" stroke="%23B2EBF2" stroke-width="1"/><rect x="20" y="20" width="10" height="10" transform="rotate(45, 25, 25)" fill="white" fill-opacity="0.7"/><circle cx="20" cy="20" r="2" fill="%23B2EBF2"/><circle cx="30" cy="30" r="2" fill="%23B2EBF2"/><circle cx="20" cy="30" r="2" fill="%23B2EBF2"/><circle cx="30" cy="20" r="2" fill="%23B2EBF2"/></svg>');
  animation-delay: 8s;
}

.virus-icon {
  top: 15%;
  right: 30%;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="%23B2EBF2"><circle cx="25" cy="25" r="10" fill-opacity="0.5" stroke="%23B2EBF2" stroke-width="1"/><path d="M25 10 L25 15 M25 35 L25 40 M10 25 L15 25 M35 25 L40 25 M15 15 L18 18 M32 32 L35 35 M15 35 L18 32 M32 18 L35 15" stroke="%23B2EBF2" stroke-width="1"/><circle cx="25" cy="25" r="2" fill="white"/><circle cx="30" cy="22" r="2" fill="white"/><circle cx="22" cy="30" r="2" fill="white"/></svg>');
  animation-delay: 11s;
}

.brain-icon {
  top: 60%;
  left: 30%;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="%23B2EBF2"><path d="M15 25 C15 20, 20 15, 25 15 C30 15, 35 20, 35 25 C35 30, 30 35, 25 35 C20 35, 15 30, 15 25 Z" fill-opacity="0.5" stroke="%23B2EBF2" stroke-width="1"/><path d="M25 15 C25 15, 20 20, 25 25 C30 30, 25 35, 25 35" stroke="white" stroke-width="1"/><path d="M15 25 C15 25, 20 20, 25 25 C30 30, 35 25, 35 25" stroke="white" stroke-width="1"/></svg>');
  animation-delay: 14s;
}

@keyframes floatElement {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }
  25% {
    transform: translate(10px, -10px) rotate(5deg);
  }
  50% {
    transform: translate(0, -20px) rotate(0deg);
  }
  75% {
    transform: translate(-10px, -10px) rotate(-5deg);
  }
  100% {
    transform: translate(0, 0) rotate(0deg);
  }
}

.footer-info {
  position: absolute;
  bottom: 15px;
  left: 0;
  width: 100%;
  text-align: center;
  color: rgba(255, 255, 255, 0.7);
  font-size: 12px;
  letter-spacing: 1px;
  animation: fadeIn 1.5s ease-out backwards;
  animation-delay: 1.2s;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
}
</style>
