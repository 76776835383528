<template>
  <div class="message-wrapper" :class="{ 'ai-message': message.type === 'ai', 'user-message': message.type === 'user' }">
    <div class="message-avatar" v-if="message.type === 'ai'">
      <div class="ai-avatar">
        <div class="ai-avatar-inner">
          <i class="el-icon-cpu"></i>
        </div>
      </div>
    </div>

    <div class="message-content">
      <div class="message-bubble" :class="{ 'ai-bubble': message.type === 'ai', 'user-bubble': message.type === 'user' }">
        <template v-if="message.type === 'ai'">
          <!-- 在处理思考过程时把内容分成三部分：前、中、后 -->
          <template v-if="hasThinkingProcess">
            <!-- 思考过程区域 -->
              <div class="thinking-content markdown-content" v-html="thinkContentHtml"></div>
            <!-- 思考后内容 -->
            <div class="message-text markdown-content" v-if="afterThinkHtml" v-html="afterThinkHtml"></div>
          </template>
          <!-- 没有思考过程的常规消息 -->
          <div v-else class="message-text markdown-content" v-html="formattedAIMessage"></div>
        </template>
        <div class="message-text" v-else>{{ message.content }}</div>
      </div>
      <div class="message-time">{{ message.time }}</div>
    </div>

    <div class="message-avatar" v-if="message.type === 'user'">
      <div class="user-avatar">
        <i class="el-icon-user"></i>
      </div>
    </div>
  </div>
</template>

<script>
import MarkdownIt from 'markdown-it'

export default {
  name: 'AiMessage',
  props: {
    message: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      md: new MarkdownIt({
        html: false,         // 禁用HTML标签
        breaks: true,        // 将换行符转换为<br>
        linkify: true,       // 自动转换URL为链接
        typographer: true,   // 启用一些语言中立的替换和引号
        highlight: null      // 代码高亮函数，这里不用
      }),
      hasThinkingProcess: false,
      beforeThinkHtml: '',
      thinkContentHtml: '',
      afterThinkHtml: ''
    }
  },
  created() {
    // 页面创建时处理内容
    this.processContent();
  },
  watch: {
    // 监听消息内容变化，重新处理内容
    'message.content': function() {
      this.processContent();
    }
  },
  methods: {
    processContent() {
      const content = this.message.content || '';
      
      // 检查是否包含推理过程标记 <think>
      if (content.includes('<think>') && this.message.type === 'ai') {
        try {
          // 提取推理过程
          const parts = content.split('<think>');
          const beforeThink = parts[0] || '';
          
          // 处理think标签内容
          let thinkContent = '';
          let afterThink = '';
          
          if (parts[1]) {
            const thinkParts = parts[1].split('</think>');
            thinkContent = thinkParts[0] || '';
            afterThink = thinkParts[1] || '';
          }
          
          // 将各部分渲染为Markdown并应用高亮
          this.beforeThinkHtml = beforeThink ? this.addHighlighting(this.md.render(beforeThink)) : '';
          this.thinkContentHtml = thinkContent ? this.addHighlighting(this.md.render(thinkContent)) : '暂无思考内容';
          this.afterThinkHtml = afterThink ? this.addHighlighting(this.md.render(afterThink)) : '';
          
          this.hasThinkingProcess = true;
        } catch (error) {
          console.error('解析思考过程出错:', error);
          this.hasThinkingProcess = false;
        }
      } else {
        this.hasThinkingProcess = false;
      }
    },
    formattedAIMessage() {
      // 没有思考过程时的普通消息处理
      const content = this.message.content || '';
      const renderedMarkdown = this.md.render(content);
      return this.addHighlighting(renderedMarkdown);
    },
    addHighlighting(text) {
      // 简单的关键词高亮处理
      const keywords = ['提示', '注意', '建议', '重要', '请记住']
      let highlightedText = text

      keywords.forEach(keyword => {
        const regex = new RegExp(`(${keyword})`, 'g')
        highlightedText = highlightedText.replace(regex, '<span class="highlight">$1</span>')
      })

      return highlightedText
    }
  }
}
</script>

<style scoped>
.message-wrapper {
  display: flex;
  margin-bottom: 24px;
  animation: messageSlideIn 0.3s ease-out;
  animation-fill-mode: both;
}

.ai-message {
  justify-content: flex-start;
}

.user-message {
  justify-content: flex-end;
}

.message-avatar {
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  margin: 0 12px;
}

.ai-avatar {
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, #3178c6, #4a9eff);
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(49, 120, 198, 0.3);
}

.ai-avatar-inner {
  width: 36px;
  height: 36px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: pulse 2s infinite;
}

.ai-avatar i {
  color: #fff;
  font-size: 18px;
}

.ai-avatar::before {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  filter: blur(5px);
  top: -5px;
  left: -5px;
  animation: moveLight 3s infinite linear;
}

.user-avatar {
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, #8b5cf6, #6366f1);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(99, 102, 241, 0.3);
}

.user-avatar i {
  color: #fff;
  font-size: 18px;
}

.message-content {
  max-width: 70%;
  text-align: left;
}

.message-bubble {
  padding: 12px 16px;
  border-radius: 14px;
  position: relative;
  margin-bottom: 4px;
}

.ai-bubble {
  background: linear-gradient(135deg, rgba(30, 41, 59, 0.8), rgba(30, 41, 59, 0.9));
  border: 1px solid rgba(74, 158, 255, 0.3);
  color: #e2e8f0;
  border-top-left-radius: 2px;
  backdrop-filter: blur(10px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), inset 0 1px 0 rgba(255, 255, 255, 0.1);
}

.ai-bubble::before {
  content: '';
  position: absolute;
  left: -6px;
  top: 14px;
  width: 12px;
  height: 12px;
  background: linear-gradient(135deg, rgba(30, 41, 59, 0.8), rgba(30, 41, 59, 0.9));
  transform: rotate(45deg);
  border-left: 1px solid rgba(74, 158, 255, 0.3);
  border-bottom: 1px solid rgba(74, 158, 255, 0.3);
}

.user-bubble {
  background: linear-gradient(135deg, #6366f1, #8b5cf6);
  color: #fff;
  border-top-right-radius: 2px;
  box-shadow: 0 4px 6px rgba(99, 102, 241, 0.3);
}

.user-bubble::before {
  content: '';
  position: absolute;
  right: -6px;
  top: 14px;
  width: 12px;
  height: 12px;
  background: linear-gradient(135deg, #6366f1, #8b5cf6);
  transform: rotate(45deg);
}

.message-text {
  line-height: 1.6;
  font-size: 15px;
  text-align: left;
}

.message-text /deep/ .highlight {
  color: #4a9eff;
  font-weight: 500;
}

.message-time {
  font-size: 12px;
  color: rgba(148, 163, 184, 0.8);
  margin-top: 4px;
  padding-left: 8px;
}

@keyframes messageSlideIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(74, 158, 255, 0.5);
  }
  70% {
    box-shadow: 0 0 0 8px rgba(74, 158, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(74, 158, 255, 0);
  }
}

@keyframes moveLight {
  0% {
    transform: translate(-5px, -5px);
  }
  25% {
    transform: translate(25px, -5px);
  }
  50% {
    transform: translate(25px, 25px);
  }
  75% {
    transform: translate(-5px, 25px);
  }
  100% {
    transform: translate(-5px, -5px);
  }
}

@keyframes pulseDot {
  0%, 100% {
    opacity: 0.6;
    box-shadow: 0 0 8px 2px rgba(74, 158, 255, 0.4);
  }
  50% {
    opacity: 1;
    box-shadow: 0 0 12px 3px rgba(74, 158, 255, 0.6);
  }
}
</style>

<style>
.markdown-content {
  text-align: left;
}

.markdown-content h1,
.markdown-content h2,
.markdown-content h3,
.markdown-content h4,
.markdown-content h5,
.markdown-content h6 {
  margin-top: 16px;
  margin-bottom: 12px;
  color: #e2e8f0;
  font-weight: 600;
}

.markdown-content h1 {
  font-size: 22px;
  border-bottom: 1px solid rgba(74, 158, 255, 0.3);
  padding-bottom: 8px;
}

.markdown-content h2 {
  font-size: 20px;
}

.markdown-content h3 {
  font-size: 18px;
}

.markdown-content h4 {
  font-size: 16px;
}

.markdown-content p {
  margin: 8px 0;
}

.markdown-content ul,
.markdown-content ol {
  padding-left: 20px;
  margin: 8px 0;
}

.markdown-content li {
  margin-bottom: 4px;
}

.markdown-content blockquote {
  padding: 8px 16px;
  border-left: 4px solid rgba(74, 158, 255, 0.5);
  background: rgba(30, 41, 59, 0.5);
  margin: 12px 0;
  border-radius: 4px;
}

.markdown-content pre {
  background: rgba(22, 33, 54, 0.8);
  padding: 12px;
  border-radius: 6px;
  overflow-x: auto;
  margin: 12px 0;
  border: 1px solid rgba(74, 158, 255, 0.2);
}

.markdown-content code {
  font-family: 'Courier New', Courier, monospace;
  background: rgba(22, 33, 54, 0.5);
  padding: 2px 4px;
  border-radius: 3px;
  font-size: 14px;
  color: #4a9eff;
}

.markdown-content pre code {
  background: transparent;
  padding: 0;
  color: #e2e8f0;
  font-family: 'Courier New', Courier, monospace;
}

.markdown-content a {
  color: #4a9eff;
  text-decoration: none;
  border-bottom: 1px dashed rgba(74, 158, 255, 0.5);
}

.markdown-content a:hover {
  text-decoration: none;
  border-bottom: 1px solid rgba(74, 158, 255, 0.8);
}

.markdown-content hr {
  border: none;
  border-top: 1px solid rgba(74, 158, 255, 0.3);
  margin: 16px 0;
}

.markdown-content table {
  border-collapse: collapse;
  width: 100%;
  margin: 16px 0;
}

.markdown-content table th,
.markdown-content table td {
  border: 1px solid rgba(74, 158, 255, 0.3);
  padding: 8px 12px;
  text-align: left;
}

.markdown-content table th {
  background: rgba(30, 41, 59, 0.8);
  font-weight: 600;
}

.markdown-content table tr:nth-child(even) {
  background: rgba(30, 41, 59, 0.4);
}


.thinking-header i {
  color: #4a9eff;
  margin-right: 8px;
  font-size: 16px;
}

.thinking-header:after {
  content: '';
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  width: 6px;
  height: 6px;
  background-color: #4a9eff;
  border-radius: 50%;
  box-shadow: 0 0 8px 2px rgba(74, 158, 255, 0.4);
  animation: pulseDot 2s infinite;
}

.thinking-content {
  padding: 16px;
  color: #94a3b8;
  font-size: 14px;
  line-height: 1.6;
  background-color: rgba(16, 24, 39, 0.4);
}

/* 覆盖thinking-content内的markdown样式 */
.thinking-content.markdown-content p {
  margin: 8px 0;
  color: #94a3b8;
}

.thinking-content.markdown-content a {
  color: #4a9eff;
  text-decoration: none;
  border-bottom: 1px dashed rgba(74, 158, 255, 0.5);
}

.thinking-content.markdown-content a:hover {
  border-bottom: 1px solid rgba(74, 158, 255, 0.8);
}

.thinking-content.markdown-content pre {
  background: rgba(12, 20, 33, 0.6);
  padding: 12px;
  border-radius: 6px;
  overflow-x: auto;
  margin: 12px 0;
  border: 1px solid rgba(74, 158, 255, 0.2);
}

.thinking-content.markdown-content code {
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
  font-size: 13px;
  background: rgba(30, 41, 59, 0.5);
  padding: 2px 4px;
  border-radius: 3px;
  color: #4a9eff;
}

.thinking-content.markdown-content pre code {
  background: transparent;
  padding: 0;
  color: #e2e8f0;
}

.thinking-content.markdown-content ol,
.thinking-content.markdown-content ul {
  padding-left: 20px;
  margin: 8px 0;
}

.thinking-content.markdown-content li {
  margin-bottom: 4px;
}

.thinking-content.markdown-content blockquote {
  padding: 8px 16px;
  border-left: 4px solid rgba(74, 158, 255, 0.5);
  background: rgba(16, 24, 39, 0.5);
  margin: 12px 0;
  border-radius: 4px;
}
</style> 